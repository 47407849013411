import React from "react";
import styled from "styled-components";
import colors from "../utils/colors";
import constants from "../utils/constants";
import { observer } from "mobx-react";
import MainNavigationElements from "./MainNavigationElements";
import { onDesktop } from "../utils/breakpoint";

const Container = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  height: ${constants.EMPLOYEE_UI_FOOTER_HEIGHT};
  flex-direction: row;
  background-color: ${colors.BLUE};
  margin: 0;
  z-index: 10;

  &, a, a:hover, a:active {
    color: ${colors.WHITE};
  }

  ${onDesktop} {
    display: none;
  }
`;

const MobileFooter: React.FunctionComponent = observer(() => {
  return (
    <Container>
      <MainNavigationElements />
    </Container>
  );
});
export default MobileFooter;
