import React from "react";
import styled from "styled-components";
import { FiInfo, FiClock, FiMessageSquare, FiGrid } from "react-icons/fi";
import { Link } from "react-router-dom";
import { onDesktop } from "../utils/breakpoint";
import colors from "../utils/colors";

interface IProps {
  title: string;
  icon: string;
  redirect: string;
  showOnDesktop: boolean;
  active: boolean;
}

const ButtonContainer = styled.div`
  height: 100%;
  box-sizing: border-box;
  width: 70px;
  border: 0;
  border-bottom: 2px solid ${(p: IProps) => p.active ? colors.BLUE : "transparent"};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  &:focus {
    outline: none;
  }
  background-color: inherit;

  ${onDesktop} {
    flex-direction: row;
    width: auto;
  }
`;

const NormalLink = styled(Link)`
  ${onDesktop} {
    flex: 1 1 0;
  }
  margin: 0 8px;
`;

const LinkWhichIsOnlyShownOnMobile = styled(Link)`
  ${onDesktop} {
    display: none;
  }
`;

const NavBtnTitle = styled.p`
  margin-top: 2px;
  height: 12px;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.36px;
  line-height: 12px;
  ${onDesktop} {
    margin-left: 8px;
    font-size: 16px;
  }
`;

const NavButton: React.FunctionComponent<IProps> = (props: IProps) => {
  const { title, icon, redirect, showOnDesktop } = props;

  const StyledLink = showOnDesktop ? NormalLink : LinkWhichIsOnlyShownOnMobile;

  const renderIcon = () => {
    switch (icon) {
      case "info":
        return <FiInfo size={20} />;
      case "clock":
        return <FiClock size={20} />;
      case "floor":
        return <FiGrid size={20} />;
      case "msg":
        return <FiMessageSquare size={20} />;
    }
  };

  return (
    <StyledLink to={`${redirect}`} style={{ textDecoration: "none" }}>
      <ButtonContainer {...props}>
        {renderIcon()}
        <NavBtnTitle>{title}</NavBtnTitle>
      </ButtonContainer>
    </StyledLink>
  );
};
export default NavButton;
