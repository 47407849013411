import React, { Component } from "react";
import styled from "styled-components";
import { observer, inject } from "mobx-react";
import colors from "../../../utils/colors";
import EmployeeUiLayout, { WithoutNormalContentAreaPadding } from "../../../components-mobile/EmployeeUiLayout";
import Slider from "../../../components-mobile/Slider";
import { InjectedFloorPlanStore, FloorPlanStore } from "../../../stores/floorplan";
import { withTranslation, WithTranslation } from 'react-i18next';
import ContactButtons from "../../../components-mobile/ContactButtons";
import { onDesktop } from "../../../utils/breakpoint";
import { Title, GreenTitle } from "../../../components/BrandText";

const ContactButtonContainer = styled.div`
  margin-top: 12px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  ${onDesktop} {
    grid-gap: 24px;
    grid-template-rows: 13rem 13rem;
  }
`;

const MissingValueText = styled.p`
  font-size: 14px;
  color: ${colors.DARK_GRAY};
  margin: 20px;
  margin-top: 10px;
  font-weight: 400;
`;

type IState = {};
type IProps = WithTranslation & {
  floorPlanStore: FloorPlanStore,
};

@inject("floorPlanStore")
@observer
class Contact extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  get injected() {
    return this.props as InjectedFloorPlanStore;
  }

  async componentDidMount() {
    // This check is for preventing making the first API request too early when this component is used in admin UI
    if (this.injected.floorPlanStore.backendPassword !== "" && this.injected.floorPlanStore.contactInfo.length === 0) {
      this.injected.floorPlanStore.fetchContacts();
    }
  }

  render() {
    const { t } = this.props;
    const { contactInfo } = this.injected.floorPlanStore;

    return (
      <EmployeeUiLayout>
        <Title>
          {t('contact.title')}
        </Title>
        {contactInfo && contactInfo.length > 0 ? (
          <WithoutNormalContentAreaPadding>
            <Slider contactInfo={contactInfo} />
          </WithoutNormalContentAreaPadding>
        ) : (
          <MissingValueText>{t('contact.no_contacts')}</MissingValueText>
        )}

        <GreenTitle>
          {t('contact.relokator_subtitle')}
        </GreenTitle>
        <WithoutNormalContentAreaPadding>
          <ContactButtonContainer>
            <ContactButtons appearance="image" />
          </ContactButtonContainer>
        </WithoutNormalContentAreaPadding>
      </EmployeeUiLayout>
    );
  }
}

export default withTranslation()(Contact);
