import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import EmployeeUiLayout from "../../../components-mobile/EmployeeUiLayout";
import ReturnButton from "../../../components-mobile/ReturnButton";
import { BrandButtonPrimary, BrandButtonNegative } from "../../../components/BrandButton";
import { FloorPlanStore } from "../../../stores/floorplan";
import { FeedbackType } from "../../../types/api";
import { FormField, FormLabel, FormTextField, QuestionField, BtnContainer, Description } from "./QuestionForm";
import { RouteComponentProps } from "react-router";
import { Trans, withTranslation, WithTranslation } from "react-i18next";
import { Title } from "../../../components/BrandText";

/*
  PLEASE NOTE!
  All styled components are defined in ./QuestionForm.tsx for consistency
*/

type IProps = RouteComponentProps<any> & WithTranslation & {
  floorPlanStore: FloorPlanStore;
}

interface IState {
  name: string;
  email: string;
  number: string;
  question: string;
}

@inject("floorPlanStore")
@observer
class FeedbackForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      name: "",
      email: "",
      number: "",
      question: "",
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit = () => {
    this.props.floorPlanStore.sendFeedback({
      name: this.state.name,
      email: this.state.email,
      phone: this.state.number,
      feedback_type: FeedbackType.Feedback,
      question: this.state.question,
    });
    this.setState({
      name: "",
      email: "",
      number: "",
      question: "",
    });
  };

  render() {
    const { t } = this.props;
    return (
      <EmployeeUiLayout>
        <ReturnButton history={this.props.history} to="/move/contact" title={t('contact.return_button_link')} />
        <Title>
          {t('feedback_form.title')}
        </Title>

        <Description>
          <Trans i18nKey="feedback_form.description" />
        </Description>
        <FormField>
          <FormLabel>{t('form.name.label')}</FormLabel>
          <FormTextField
            placeholder={t('form.name.placeholder')}
            value={this.state.name}
            onChange={e => this.setState({ name: e.target.value })}
          />
        </FormField>
        <FormField>
          <FormLabel>{t('form.email.label')}</FormLabel>
          <FormTextField
            placeholder={t('form.email.placeholder')}
            value={this.state.email}
            onChange={e => this.setState({ email: e.target.value })}
          />
        </FormField>
        <FormField>
          <FormLabel>{t('form.phone.label')}</FormLabel>
          <FormTextField
            placeholder={t('form.phone.placeholder')}
            value={this.state.number}
            onChange={e => this.setState({ number: e.target.value })}
          />
        </FormField>
        <FormField>
          <FormLabel>{t('feedback_form.feedback.label')} *</FormLabel>
          <QuestionField
            rows={5}
            placeholder={t('feedback_form.feedback.placeholder')}
            value={this.state.question}
            onChange={e => this.setState({ question: e.target.value })}
          />
        </FormField>

        <BtnContainer>
          <BrandButtonNegative
            onClick={() => {
              this.setState({
                name: "",
                email: "",
                number: "",
                question: "",
              });
              this.props.history.push("/move/contact");
            }}
          >
            {t('buttons.cancel')}
          </BrandButtonNegative>
          <BrandButtonPrimary onClick={() => this.onSubmit()} disabled={this.state.question === ""}>
            {t('buttons.send')}
          </BrandButtonPrimary>
        </BtnContainer>
      </EmployeeUiLayout>
    );
  }
}

export default withTranslation()(FeedbackForm);
