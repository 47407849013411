import React, { Component } from "react";
import { TFunction } from "i18next";
import styled from "styled-components";
import { observer, inject } from "mobx-react";
import colors from "../../../utils/colors";
import EmployeeUiLayout, { WithoutNormalContentAreaPadding } from "../../../components-mobile/EmployeeUiLayout";
import InfoCard from "../../../components-mobile/InformationCard";
import { InfoType, PageDocumentWithTranslations } from "../../../types/move";
import { FloorPlanStore } from "../../../stores/floorplan";
import { withTranslation, WithTranslation } from 'react-i18next';
import { MoveStore } from "../../../stores/move";
import { onDesktop, OnlyOnDesktop } from "../../../utils/breakpoint";
import ContactButtons from "../../../components-mobile/ContactButtons";
import TextBlockLink from "../../../components-mobile/TextBlockLink";
import FloorImageThumbnail from "../../../components-mobile/FloorImageThumbnail";
import Schedule from "../../../components-mobile/Schedule";
import { FiChevronRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import { Title, GreenTitle } from "../../../components/BrandText";

const Columns = styled.div`
  ${onDesktop} {
    display: grid;
    grid-gap: 32px;
    grid-template-columns: 1fr 1fr;
  }
`;

const LeftColumn = styled.div`
`;

const RightColumn = styled.div`
  display: none;
  ${onDesktop} {
    display: block;
  }
`;

const LinkArea = styled(Link)`
  text-decoration: none;
`;

const titleChanges = `
  ${onDesktop} {
    color: ${colors.WHITE};
  }
  svg {
    vertical-align: text-bottom;
  }
`;
const Title2 = styled(Title)`${titleChanges}`;
const GreenTitle2 = styled(GreenTitle)`${titleChanges}`;

const WhiteBackground = styled.div`
  background-color: ${colors.WHITE};
  padding: 32px;
`;

const InfoDocuments = styled.div`
  margin-top: 20px;
`;

type IProps = WithTranslation & {
  floorPlanStore: FloorPlanStore;
  moveStore?: MoveStore;
}

@inject("floorPlanStore")
@inject("moveStore")
@observer
class InformationDashboard extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.renderDocuments = this.renderDocuments.bind(this);
  }

  async componentDidMount() {
    // This check is for preventing making the first API request too early when this component is used in admin UI
    if (this.props.floorPlanStore.backendPassword !== "") {
      this.props.floorPlanStore.fetchPages();
    }
  }

  renderDocuments = (documents: PageDocumentWithTranslations[], language: string) => documents.map((document, i) => {
    const translation = document.translations.find(t => t.locale === language)
      || document.translations[0];

    return <TextBlockLink
      color={i % 2 === 0 ? 'darkgreen' : 'green'}
      key={i}
      to={"/move/info/doc/" + document.id}
      text={translation ? translation.title : '-'}
    />;
  });

  renderFaq = (documents: Map<any, any>, t: TFunction) => {
    return (
      <TextBlockLink
        color={documents.size % 2 === 0 ? "darkgreen" : "green"}
        to="/move/FAQ"
        text={t("contact.actions.faq")}
      />
    );
  };

  render() {
    if (!this.props.moveStore) {
      return null;
    }

    const { t } = this.props;
    const { documents } = this.props.floorPlanStore;
    const { locale } = this.props.moveStore;

    const arrow = <FiChevronRight />;

    return (
      <EmployeeUiLayout backgroundStyle="cover">
        <Columns>
          <LeftColumn>

            <Title2>{t('info.title')}</Title2>
            <InfoCard
              infoType={InfoType.General}
              infoData={this.props.floorPlanStore.moveKeyInfo}
            />

            <GreenTitle2>{t('info.documents_subtitle')}</GreenTitle2>
            <WithoutNormalContentAreaPadding>
              <InfoDocuments>
                {this.renderDocuments(Array.from(documents.values()), locale.backend)}
                {this.renderFaq(documents, t)}
              </InfoDocuments>
            </WithoutNormalContentAreaPadding>

            <OnlyOnDesktop>
              <LinkArea to="/move/contact">
                <Title2>{t('contact.title')} {arrow}</Title2>
              </LinkArea>
              <ContactButtons appearance="text" backTo="/move/info" backText={t("info.title")} />
            </OnlyOnDesktop>

          </LeftColumn>
          <RightColumn>
            <OnlyOnDesktop>

              <Title2>{t('schedule.title')}</Title2>
              <WhiteBackground>
                <Schedule />
              </WhiteBackground>

              <LinkArea to="/move/floor">
                <Title2>{t('floor_plan.title')} {arrow}</Title2>
                <WhiteBackground>
                  <FloorImageThumbnail />
                </WhiteBackground>
              </LinkArea>

            </OnlyOnDesktop>
          </RightColumn>
        </Columns>
      </EmployeeUiLayout>
    );
  }
}

export default withTranslation()(InformationDashboard);
