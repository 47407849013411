import { SpaceTag, TableTag, Floor, Owner } from "../types/floorplan";

export const isSpaceEdited = (editTag: SpaceTag, spaceNumber: string, openSpace: boolean) => {
  const original = {
    title: editTag.title,
    openSpace: editTag.openSpace,
  };

  const edited = {
    title: spaceNumber,
    openSpace,
  };

  return original !== edited;
};

export const isTableEdited = (editTag: TableTag, spaceNumber: string, tableLetter: string) => {
  const original = {
    spaceNumber: editTag.space,
    tableLetter: editTag.letter,
  };

  const edited = {
    spaceNumber,
    tableLetter,
  };

  return original !== edited;
};

export const getSpaceByTag = (floor: Floor, spaceTag: SpaceTag) => {
  const index = floor.spaces.findIndex(space => {
    return space.title === spaceTag.title;
  });
  return index > -1 ? floor.spaces[index] : null;
};

export const getSpaceByTable = (floor: Floor, table: TableTag) => {
  const index = floor.spaces.findIndex(space => {
    return space.title === table.space;
  });
  return index > -1 ? floor.spaces[index] : null;
};

export const getTableByTag = (space: SpaceTag, tableTag: TableTag) => {
  const index = space.tables.findIndex(table => {
    return table === tableTag;
  });
  return index > -1 ? space.tables[index] : null;
};

export const getTableByLetter = (space: SpaceTag, tableLetter: string) => {
  if (tableLetter === "-") {
    return null;
  }
  const index = space.tables.findIndex(table => {
    return table.letter === tableLetter;
  });
  return index > -1 ? space.tables[index] : null;
};

export const getOwner = (list: Owner[], owner: Owner) => {
  const index = list.findIndex(item => {
    return item === owner;
  });
  return index > -1 ? list[index] : null;
};
