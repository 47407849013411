import React from "react";
import { FloorPlanStore } from "./floorplan";
import { LabelsStore } from "./labels";
import { SpacesStore } from "./spaces";
import { MoveStore } from "./move";
import { ApplicationStore } from "./application";
import { PeopleStore } from "./people";

export type Stores = {
  floorPlanStore: FloorPlanStore;
  labelsStore: LabelsStore;
  spacesStore: SpacesStore;
  moveStore: MoveStore;
  peopleStore: PeopleStore;
  applicationStore: ApplicationStore;
}

class StoresComponent extends React.Component {

  public floorPlanStore = new FloorPlanStore(this);
  public labelsStore = new LabelsStore(this);
  public spacesStore = new SpacesStore(this);
  public moveStore = new MoveStore(this);
  public peopleStore = new PeopleStore(this);
  public applicationStore = new ApplicationStore(this);

  public stores: Stores = ({
    floorPlanStore: this.floorPlanStore,
    labelsStore: this.labelsStore,
    spacesStore: this.spacesStore,
    moveStore: this.moveStore,
    peopleStore: this.peopleStore,
    applicationStore: this.applicationStore,
  });
}

export default StoresComponent;