import React from "react";
import { useTranslation } from "react-i18next";
import ContactButton from "./ContactButton";

type IProps = {
  appearance: "image" | "text";
  backText?: string;
  backTo?: string;
};

const ContactButtons: React.FunctionComponent<IProps> = (props: IProps) => {
  const { t } = useTranslation();
  const { backText, backTo, appearance } = props;

  let searchParams = "";
  if (backText && backTo) {
    searchParams = `?rt=${encodeURIComponent(backText)}&rp=${encodeURIComponent(backTo)}`;
  }

  return (
    <>
      <ContactButton
        column={1}
        span={2}
        title={t("contact.actions.question")}
        image="question.jpeg"
        route={`/move/contact/ask${searchParams}`}
        appearance={appearance}
      />
      <ContactButton
        column={1}
        span={1}
        title={t("contact.actions.error")}
        image="error.jpeg"
        route={`/move/contact/error-report${searchParams}`}
        appearance={appearance}
      />
      <ContactButton
        column={2}
        span={1}
        title={t("contact.actions.feedback")}
        image="feedback.jpeg"
        route={`/move/contact/feedback${searchParams}`}
        appearance={appearance}
      />
    </>
  );
};
export default ContactButtons;
