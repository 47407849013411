import styled from "styled-components";
import colors from "../utils/colors";

interface ColorValues {
  text: string;
  background: string;
  border: string;
  hoverBackground: string;
  hoverBorder: string;
  hoverColor?: string;
  borderRadius?: string;
}

const colorVariation = ({
  text,
  background,
  border,
  hoverBackground,
  hoverBorder,
  hoverColor,
  borderRadius,
}: ColorValues) => `
  color: ${text};
  border-color: ${border};
  background-color: ${background};
  ${borderRadius && `border-radius: ${borderRadius}`}
  
  &:hover {
    color: ${hoverColor || text};
    border-color: ${hoverBorder};
    background-color: ${hoverBackground};
  }
  &:active {
    color: ${text};
    border-color: ${hoverBorder};
    background-color: ${hoverBackground};
  }
`;

const buttonWidth = 140;
const buttonHeight = 30;

const BrandButtonBase = styled.button`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.58px;
  line-height: 14px;
  text-transform: uppercase;

  width: ${buttonWidth}px;
  height: ${buttonHeight}px;
  border-radius: ${buttonHeight / 2}px;
  &:active {
    transform: scale(${(buttonWidth - 2) / buttonWidth}, ${(buttonHeight - 2) / buttonHeight});
  }

  border-width: 1px;
  border-style: solid;

  &:focus {
    outline: none;
  }
`;

export const BrandButtonPrimary = styled(BrandButtonBase)`
  ${props =>
    colorVariation({
      text: colors.WHITE,
      background: props.theme.secondaryColor || colors.GREEN_DARK,
      border: props.theme.secondaryColor || colors.GREEN_DARK,
      hoverBackground: props.theme.secondaryColorLight || colors.GREEN_DARKEST,
      hoverBorder: props.theme.secondaryColorDarkLight || colors.GREEN_DARKEST,
      borderRadius: props.theme.buttonBorderRadius,
    })}
  font-weight: 700;
`;

export const BrandButtonSecondary = styled(BrandButtonBase)`
  ${props =>
    colorVariation({
      text: props.theme.primaryColorLight || colors.GREEN_DARK,
      background: colors.TRANSPARENT,
      border: props.theme.primaryColorLight || colors.GREEN_DARK,
      hoverBackground: props.theme.primaryColorLighter || colors.GREEN_LIGHTEST,
      hoverBorder: props.theme.primaryColorLight || colors.GREEN_DARK,
      borderRadius: props.theme.buttonBorderRadius,
    })}
`;

export const BrandButtonTertiary = styled(BrandButtonBase)`
  ${props =>
    colorVariation({
      text: props.theme.fontColorDark || colors.TITLE_DEFAULT,
      background: colors.TRANSPARENT,
      border: props.theme.tertiaryColor || colors.TITLE_DEFAULT,
      hoverBackground: props.theme.tertiaryColorLight || colors.BLUE_LIGHTEST,
      hoverBorder: props.theme.tertiaryColor || colors.TITLE_DEFAULT,
      hoverColor: props.theme.fontColorDark || colors.TITLE_DEFAULT,
      borderRadius: props.theme.buttonBorderRadius,
    })}
`;

export const BrandButtonNegative = styled(BrandButtonBase)`
  ${props =>
    colorVariation({
      text: props.theme.primaryColorLight || colors.RED,
      background: colors.TRANSPARENT,
      border: props.theme.primaryColorLight || colors.RED,
      hoverBackground: props.theme.primaryColorLighter || colors.RED_LIGHTEST,
      hoverBorder: props.theme.primaryColorLight || colors.RED,
      hoverColor: props.theme.primaryColorLight || colors.RED,
      borderRadius: props.theme.buttonBorderRadius,
    })}
`;
