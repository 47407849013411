import styled from "styled-components";

// @ts-ignore
import ScrollContainer from 'react-indiana-drag-scroll';

export const FloorImageContainer = styled(ScrollContainer).attrs(props => ({
  ignoreElements: ".floorplanner-marker-tool",
}))`
  position: relative;
  height: 100%;
  overflow: auto;
`;
