import { lighten } from "polished";

export interface Theme {
  primaryColor: string;
  primaryColorLight: string;
  primaryColorLighter: string;

  secondaryColor: string;
  secondaryColorLight: string;
  secondaryColorLighter: string;

  tertiaryColor: string;
  tertiaryColorLight: string;

  buttonBorderRadius: string;

  fontColorDark: string;

  supportingColor: string;
  supportingColorDark: string;
  supportingColorLight: string;
}

export const floorPlannerAdminTheme: Theme = {
  primaryColor: "#60285E",
  primaryColorLight: "#AD37A9",
  primaryColorLighter: lighten(0.5, "#AD37A9"),

  secondaryColor: "#277A6F",
  secondaryColorLight: "#2F9487",
  secondaryColorLighter: "#40AD9F",

  tertiaryColor: lighten(0.7, "#000000"),
  tertiaryColorLight: lighten(0.95, "#000000"),

  buttonBorderRadius: "3px",

  fontColorDark: "#3C3C3C",

  supportingColor: "#0685BB",
  supportingColorDark: "#404040",
  supportingColorLight: "#D9D9D9",
};
