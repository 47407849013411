import styled from "styled-components";
import colors from "../utils/colors";

export const Table = styled.table`
  table-layout: fixed;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
  border-spacing: 0;
  border-collapse: collapse;
`;

export const TableHead = styled.thead`
  background-color: ${props => props.theme.supportingColorLight || colors.TP_TURQUOISE};
  border: 1px solid ${props => props.theme.supportingColorLight || colors.TP_TURQUOISE};
`;

export const TableHeadCell = styled.th`
  text-transform: uppercase;
  color: ${props => props.theme.fontColorDark || colors.BLUE};
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.6px;
  line-height: 18px;
  padding: 2px 16px;
  text-align: left;
`;

export const TableRow = styled.tr`
  background-color: ${colors.WHITE};
`;

export const TableCell = styled.td`
  border: 1px solid ${props => props.theme.supportingColorLight || colors.TP_TURQUOISE};
  padding: 8px 16px;
  color: ${colors.TEXT_DEFAULT};
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.42px;
  line-height: 14px;
  vertical-align: middle;
`;

export const TableCellContent = styled.div`
  float: left;
  color: ${colors.TEXT_DEFAULT};
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.42px;
  line-height: 14px;
`;

export const TableCellContentNoWrap = styled(TableCellContent)`
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
`;

export const TableBody = styled.tbody`
  overflow-y: auto;
  overflow-x: hidden;
`;
