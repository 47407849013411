import React, { Component } from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import _, { last, defer } from "lodash";
import { FloorPlanStore } from "../stores/floorplan";
import MarkerTool from "./MarkerTool";
import colors from "../utils/colors";
import constants from "../utils/constants";
import {
  MarkingType,
  Floor,
  SpaceTag,
  TableTag,
  isSpaceTag,
  isTableTag,
  Option,
} from "../types/floorplan";
import { isSpaceEdited, isTableEdited } from "../utils/mark-utils";
import ImageOptions from "./ImageOptions";
import { FloorImageContainer } from "./FloorImageContainer";
import FloorImageContent from "./FloorImageContent";
import { Subtitle } from "./BrandText";
import { SpacesStore } from "../stores/spaces";

const FloorPlanMarkContainer = styled.div`
  height: calc(100% - 24px);
  padding-top: 24px;
  display: flex;
  flex-direction: column;
`;

const UpperContainer = styled.div`
  padding: 0 32px;
  flex: 0 0 auto;
`;

const LowerContainer = styled(FloorImageContainer)`
  flex: 1 1 auto;
`;

const PlanDescription = styled.p`
  color: ${colors.TEXT_DEFAULT};
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.42px;
  line-height: 18px;
  margin-top: 16px;
`;

interface IProps {
  floorPlanStore: FloorPlanStore;
  spacesStore?: SpacesStore;
}

interface IState {
  pctX: number | null;
  pctY: number | null;
  spaceNumber: string;
  tableLetter: string;
  openSpace: boolean;
  markType: MarkingType;
  editTag: SpaceTag | TableTag | null;
  spaceOptions: Option[];
  selectedOption: Option | null;
  zoomLevel: number;
}

@inject("floorPlanStore")
@inject("spacesStore")
@observer
class FloorPlanMark extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      pctX: null,
      pctY: null,
      spaceNumber: "",
      tableLetter: "",
      openSpace: false,
      markType: MarkingType.Space,
      editTag: null,
      spaceOptions: [],
      selectedOption: null,
      zoomLevel: constants.ZOOM_LEVEL_DEFAULT,
    };

    this.saveSpaceTag = this.saveSpaceTag.bind(this);
    this.saveTableTag = this.saveTableTag.bind(this);
    this.openMarkerTool = this.openMarkerTool.bind(this);
    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.validateTag = this.validateTag.bind(this);
    this.deleteTag = this.deleteTag.bind(this);
    this.updateFloor = this.updateFloor.bind(this);
    this.changeSpaceNumber = this.changeSpaceNumber.bind(this);
    this.changeTableLetter = this.changeTableLetter.bind(this);
    this.changeSelectedOption = this.changeSelectedOption.bind(this);
    this.initializeState = this.initializeState.bind(this);
    this.setZoomLevel = this.setZoomLevel.bind(this);
    this.setSuggestions = this.setSuggestions.bind(this);

    this.containerRef = React.createRef();
  }

  floorImg: any = React.createRef();
  private containerRef: React.RefObject<HTMLDivElement>;

  componentDidMount() {
    defer(this.setDefaultScrollPosition.bind(this));
  }

  setDefaultScrollPosition() {
    if (this.containerRef.current) {
      const el = this.containerRef.current.querySelector('.indiana-scroll-container');
      if (el) {
        el.scrollLeft = constants.MARKER_TOOL_WIDTH / 2;
        el.scrollTop = constants.MARKER_TOOL_WIDTH / 2;
      }
    }
  }

  // Change current floor plan.
  updateFloor = (selectedFloor: Floor) => {
    this.props.floorPlanStore.setCurrentFloor(selectedFloor);
  };

  setZoomLevel = (level: number) => {
    this.setState({ zoomLevel: level });
  };

  // Call after editing or saving new space / table.
  initializeState = () => {
    const spaceOptions = (this.props.spacesStore && this.props.floorPlanStore.currentFloor &&
      this.props.spacesStore.spaceOptionsOnAFloor(this.props.floorPlanStore.currentFloor)) || [];
    this.setState({
      editTag: null,
      pctX: null,
      pctY: null,
      spaceNumber: "",
      tableLetter: "",
      openSpace: false,
      spaceOptions: spaceOptions,
      selectedOption: null,
    });
  };

  onMarkerClick = (event: React.MouseEvent, tag: SpaceTag | TableTag) => {
    this.initializeState();
    if (isSpaceTag(tag)) {
      this.openMarkerToolEdit(tag.title, undefined);
    }
    if (isTableTag(tag)) {
      this.openMarkerToolEdit(tag.space, tag.letter);
    }
    event.stopPropagation();
  };

  setSuggestions = (markingTypeArg?: MarkingType, selectedOptionArg?: Option) => {
    const markingType = markingTypeArg || this.state.markType;
    let suggestedOption = selectedOptionArg || this.state.selectedOption || null;

    const currentFloor = this.props.floorPlanStore.currentFloor;
    if (!this.props.spacesStore || !currentFloor) {
      return;
    }

    if (markingType === MarkingType.Space) {
      this.setState({
        spaceNumber: this.props.spacesStore.suggestSpaceNumber(currentFloor) + "",
      });

    } else if (markingType === MarkingType.Table && !suggestedOption) {
      const mostRecentSpace = last(currentFloor.spaces);
      if (mostRecentSpace) {
        suggestedOption = this.state.spaceOptions.find(option => option.label === mostRecentSpace.title) || null;
        this.setState({
          spaceNumber: mostRecentSpace.title,
          tableLetter: this.props.spacesStore.suggestTableLetter(mostRecentSpace.tables),
          selectedOption: suggestedOption,
        });
      }

    } else if (markingType === MarkingType.Table && suggestedOption) {
      const space = currentFloor.spaces.find(s => s.title === suggestedOption!.label);
      if (space) {
        this.setState({
          spaceNumber: space.title,
          tableLetter: this.props.spacesStore.suggestTableLetter(space.tables),
          selectedOption: suggestedOption,
        });
      }
    }
  };

  // Opens the tool for creating a new marker in clicked position.
  openMarkerTool = (e: React.MouseEvent) => {
    if (!this.props.spacesStore || !this.props.floorPlanStore.currentFloor) {
      return;
    }
    this.setState({
      editTag: null,
      pctX: (e.nativeEvent.offsetX / this.floorImg.current.width) * 100,
      pctY: (e.nativeEvent.offsetY / this.floorImg.current.height) * 100,
      openSpace: false,
      spaceOptions: this.props.spacesStore.spaceOptionsOnAFloor(this.props.floorPlanStore.currentFloor),
    });
    this.setSuggestions();
  };

  openMarkerToolEdit = (space: string, table?: string) => {
    if (!this.props.spacesStore || !this.props.floorPlanStore.currentFloor) {
      return;
    }
    if (table) {
      const markers = this.props.floorPlanStore.tableTags;
      const marker = markers.find(m => m.space === space && m.letter === table);

      if (marker) {
        const spaceOptions = this.props.spacesStore.spaceOptionsOnAFloor(this.props.floorPlanStore.currentFloor);
        const selectedOption = spaceOptions.find(option => option.value === space);
        this.setState({
          editTag: marker,
          pctX: marker.positionX,
          pctY: marker.positionY,
          spaceNumber: marker.space,
          tableLetter: marker.letter,
          openSpace: false,
          markType: MarkingType.Table,
          spaceOptions: this.props.spacesStore.spaceOptionsOnAFloor(this.props.floorPlanStore.currentFloor),
          selectedOption: selectedOption || null,
        });
      }
    } else {
      const markers = _.isNil(this.props.floorPlanStore.currentFloor)
        ? []
        : this.props.floorPlanStore.currentFloor.spaces;
      const index = markers.findIndex(e => e.title === space);

      if (index > -1) {
        this.setState({
          editTag: markers[index],
          pctX: markers[index].positionX,
          pctY: markers[index].positionY,
          spaceNumber: markers[index].title,
          openSpace: markers[index].openSpace,
          tableLetter: "",
          markType: MarkingType.Space,
        });
      }
    }
  };

  editSpaceTag = () => {
    if (this.state.editTag && isSpaceTag(this.state.editTag)) {
      this.props.floorPlanStore.editSpace(
        this.state.editTag,
        this.state.spaceNumber,
        this.state.openSpace,
      );
      this.initializeState();
    }
  };

  editTableTag = () => {
    if (this.state.editTag && isTableTag(this.state.editTag)) {
      this.props.floorPlanStore.editTable(
        this.state.editTag,
        this.state.spaceNumber,
        this.state.tableLetter,
      );
      this.initializeState();
    }
  };

  saveSpaceTag = () => {
    if (this.state.pctX && this.state.pctY) {
      const tag: SpaceTag = {
        title: this.state.spaceNumber,
        positionX: this.state.pctX,
        positionY: this.state.pctY,
        openSpace: this.state.openSpace,
        tables: [],
      };

      this.props.floorPlanStore.addSpace(tag);
      this.initializeState();
    }
  };

  saveTableTag = () => {
    if (this.state.pctX && this.state.pctY) {
      const tag: TableTag = {
        space: this.state.spaceNumber,
        letter: this.state.tableLetter,
        positionX: this.state.pctX,
        positionY: this.state.pctY,
      };

      this.props.floorPlanStore.addTable(tag);
      this.initializeState();
    }
  };

  // CONTROLLED COMPONENT FUNCTIONS

  validateTag = () => {
    if (this.state.markType === MarkingType.Space) {
      if (this.state.editTag && isSpaceTag(this.state.editTag)) {
        if (isSpaceEdited(this.state.editTag, this.state.spaceNumber, this.state.openSpace)) {
          this.editSpaceTag();
        } else {
          // No changes to save.
          this.initializeState();
        }
      } else {
        const space = _.isNil(this.props.floorPlanStore.currentFloor)
          ? undefined
          : this.props.floorPlanStore.currentFloor.spaces.find(
              space => space.title === this.state.spaceNumber,
            );
        if (space === undefined) {
          this.saveSpaceTag();
        } else {
          // TODO: Show information view of existing space.
          this.initializeState();
        }
      }
    } else if (this.state.markType === MarkingType.Table) {
      if (this.state.editTag && isTableTag(this.state.editTag)) {
        if (isTableEdited(this.state.editTag, this.state.spaceNumber, this.state.tableLetter)) {
          this.editTableTag();
        } else {
          // No changes to save.
          this.initializeState();
        }
      } else {
        const table = this.props.floorPlanStore.tableTags.find(
          table => table.space + table.letter === this.state.spaceNumber + this.state.tableLetter,
        );
        if (table === undefined) {
          this.saveTableTag();
        } else {
          // TODO: Show information view of existing table.
          this.initializeState();
        }
      }
    }
  };

  deleteTag = () => {
    if (this.state.editTag && isSpaceTag(this.state.editTag)) {
      // if (isSpaceAssigned(this.state.editTag)) {
      //   // TODO: Show dialog to remove tag with owner
      // }
      this.props.floorPlanStore.removeSpace(this.state.editTag);
    } else if (this.state.editTag && isTableTag(this.state.editTag)) {
      // if (isTableAssigned(this.state.editTag)) {
      //   // TODO: Show dialog to remove tag with owner
      // }
      this.props.floorPlanStore.removeTable(this.state.editTag);
    }

    this.initializeState();
    console.log("Remove tag");
  };

  changeSpaceNumber = (name: string) => {
    this.setState({ spaceNumber: name });
  };

  changeTableLetter = (name: string) => {
    // Forces the letter to consist of only alpha characters
    const longString = name.toUpperCase().replace(/[^A-ZÅÄÖÆØ]/g, '');
    this.setState({ tableLetter: longString.length > 0 ? longString.charAt(0) : '' });
  };

  changeMarkingType = (type: MarkingType) => {
    this.setState({ markType: type });
    this.setSuggestions(type);
  };

  changeOpenSpace = () => {
    this.setState({ openSpace: !this.state.openSpace });
  };

  changeSelectedOption = (value: Option) => {
    this.setState({ selectedOption: value });
    this.setSuggestions(undefined, value);
  };

  render() {
    const { currentFloor, tableTags } = this.props.floorPlanStore;
    const {
      pctX,
      pctY,
      spaceNumber,
      tableLetter,
      openSpace,
      markType,
      spaceOptions,
      selectedOption,
      zoomLevel,
    } = this.state;

    // A bit dumb check for null, but TS \o/
    if (_.isNil(currentFloor)) {
      return;
    }

    return (
      <FloorPlanMarkContainer ref={this.containerRef}>
        <UpperContainer>
          <Subtitle>Add spaces and tables</Subtitle>
          <PlanDescription>
            Choose and click a spot the floor plan to mark a space or table there.{" "}
          </PlanDescription>
          <ImageOptions
            onZoom={this.setZoomLevel}
            zoomLevel={this.state.zoomLevel}
          />
        </UpperContainer>
        <LowerContainer>
          <FloorImageContent
            forwardedRef={this.floorImg}
            currentFloor={currentFloor}
            zoomLevel={zoomLevel}
            initialFit="contain"
            imageMargin={constants.MARKER_TOOL_WIDTH / 2}
            tableTags={tableTags}
            onImageClick={this.openMarkerTool}
            onMarkerClick={this.onMarkerClick}
          >
            {pctX && pctY && (
              <MarkerTool
                x={pctX}
                y={pctY}
                spaceNumber={spaceNumber}
                tableLetter={tableLetter}
                options={spaceOptions}
                selectedOption={selectedOption}
                openSpace={openSpace}
                markingType={markType}
                onOK={this.validateTag}
                onDelete={this.deleteTag}
                onClose={this.initializeState}
                onSpaceNumberChange={this.changeSpaceNumber}
                onTableLetterChange={this.changeTableLetter}
                onMarkingTypeChange={this.changeMarkingType}
                onOpenSpaceChange={this.changeOpenSpace}
                onOptionSelected={this.changeSelectedOption}
              />
            )}
          </FloorImageContent>
        </LowerContainer>
      </FloorPlanMarkContainer>
    );
  }
}

export default FloorPlanMark;
