import React from "react";
import styled from "styled-components";
import Zoom from "../components-shared/Zoom";
import { SubtitleWithoutMargins } from "./BrandText";
import FloorSelect from "./FloorSelect";
import colors from "../utils/colors";

interface IProps {
  onZoom: (level: number) => void;
  zoomLevel: number;
  marginRight?: string | null;
}

const HelperSubtitle = styled(SubtitleWithoutMargins)`
  display: inline-block;
  margin-right: 16px;
`;

const ContentWrapper = styled.div`
  align-items: center
  display: flex;
  margin-top: 24px;
  margin-bottom: 10px;
  flex: 0 0 auto; // don't grow, take up only needed space
`;

const FlexFloorSelectContainer = styled.div`
  border: 1px solid ${props => props.theme.supportingColorLight || colors.TP_TURQUOISE};
`;

const FixedWidthZoom = styled(Zoom)`
  width: 50px;
`;

const ImageOptions: React.FunctionComponent<IProps> = (props: IProps) => {
  const { onZoom, zoomLevel, marginRight } = props;

  return (
    <ContentWrapper style={{ marginRight: marginRight ? marginRight : 0 }}>
        <HelperSubtitle>Select floor</HelperSubtitle>
        <FlexFloorSelectContainer>
          <FloorSelect />
        </FlexFloorSelectContainer>
        <HelperSubtitle style={{ marginLeft: "auto" }}>Zoom</HelperSubtitle>
        <FixedWidthZoom zoomLevel={zoomLevel} onZoom={level => onZoom(level)}/>
    </ContentWrapper>
  );
};
export default ImageOptions;
