import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import FloorPlanEdit from "../FloorPlan/FloorPlanEdit";
import { floorPlannerAdminTheme } from "../../utils/themes";

const FloorPlanEditAdmin = () => {
  return (
    <ThemeProvider theme={floorPlannerAdminTheme}>
      <FloorPlanEdit />
    </ThemeProvider>
  );
};

const FloorPlanNav: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route path="/floorplan/rpm" component={FloorPlanEditAdmin} />
        <Route path="/floorplan/cpm" component={FloorPlanEditAdmin} />
      </Switch>
    </Router>
  );
};

export default FloorPlanNav;
