import React from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import _ from "lodash";
import FloorPlanMark from "../../components/FloorPlanMark";
import FloorPlanUpload from "../../components/FloorPlanUpload";
import { FloorPlanStore } from "../../stores/floorplan";
import colors from "../../utils/colors";
import Footer from "../../components/Footer";
import constants from "../../utils/constants";
import { FloorPlanEditState } from "../../types/floorplan";
import FloorPlanConnect from "../../components/FloorPlanConnect";
import FloorPlanFinish from "../../components/FloorPlanFinish";
import { LabelsStore } from "../../stores/labels";
import { ApplicationStore } from "../../stores/application";

const MainContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  bottom: ${constants.FOOTER_HEIGHT};
  left: 0;
  right: 0;
`;

const PageContainer = styled.div`
  position: absolute;
  top: 24px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background-color: ${colors.WHITE};
  box-shadow: 0 6px 20px 4px ${colors.TP_GRAY};
`;

const helperTexts = {
  Upload: "Upload floor plan layouts",
  Mark: "Mark the areas",
  Connect: "Connect people to areas",
  Finish: "Finish the label order sheet",
};

const dirtyHackForTheHelperText = (text: string) => {
  const el = document.getElementById("titlebar_left");
  if (el) {
    el.textContent = "Floor plan:";
    el.setAttribute("data-after", text);
  }
};

const renderFloorPlanState = (floorPlanStore: FloorPlanStore) => {
  if (_.isNil(floorPlanStore.currentFloor)) {
    dirtyHackForTheHelperText(helperTexts.Upload);
    return <FloorPlanUpload floorPlanStore={floorPlanStore} />;
  }

  switch (floorPlanStore.editState) {
    case FloorPlanEditState.Upload:
      dirtyHackForTheHelperText(helperTexts.Upload);
      return <FloorPlanUpload floorPlanStore={floorPlanStore} />;

    case FloorPlanEditState.Mark:
      dirtyHackForTheHelperText(helperTexts.Mark);
      return <FloorPlanMark floorPlanStore={floorPlanStore} />;

    case FloorPlanEditState.Connect:
      dirtyHackForTheHelperText(helperTexts.Connect);
      return <FloorPlanConnect floorPlanStore={floorPlanStore} />;

    default:
      dirtyHackForTheHelperText(helperTexts.Finish);
      return <FloorPlanFinish floorPlanStore={floorPlanStore} />;
  }
};

const continueNextState = (floorPlanStore: FloorPlanStore) => {
  switch (floorPlanStore.editState) {
    case FloorPlanEditState.Upload:
      floorPlanStore.setEditState(FloorPlanEditState.Mark);
      break;
    case FloorPlanEditState.Mark:
      floorPlanStore.setEditState(FloorPlanEditState.Connect);
      break;
    case FloorPlanEditState.Connect:
      floorPlanStore.setEditState(FloorPlanEditState.Finish);
      break;
  }
};

const backToPreviousState = (floorPlanStore: FloorPlanStore) => {
  switch (floorPlanStore.editState) {
    case FloorPlanEditState.Finish:
      floorPlanStore.setEditState(FloorPlanEditState.Connect);
      break;
    case FloorPlanEditState.Connect:
      floorPlanStore.setEditState(FloorPlanEditState.Mark);
      break;
    case FloorPlanEditState.Mark:
      floorPlanStore.setEditState(FloorPlanEditState.Upload);
      break;
  }
};

const moveToState = (floorPlanStore: FloorPlanStore, state: FloorPlanEditState) => {
  floorPlanStore.setEditState(state);
};

const cancelChanges = () => {
  console.log("ABORT THE FLOORPLAN!");
};

const downloadCsvFile = (labelsStore?: LabelsStore) => {
  if (labelsStore) {
    labelsStore.downloadLabelsCsvFile();
  }
};

type IProps = {
  applicationStore?: ApplicationStore;
  floorPlanStore?: FloorPlanStore;
  labelsStore?: LabelsStore;
};

@inject("applicationStore")
@inject("floorPlanStore")
@inject("labelsStore")
@observer
class Home extends React.Component<IProps> {

  componentDidMount() {
    if (this.props.applicationStore) {
      this.props.applicationStore.enableAdminMode();
    }
  }

  render() {
    const { floorPlanStore, labelsStore } = this.props;

    if (!floorPlanStore) {
      return <></>;
    }

    return (
      <MainContainer>
        <Content>
          <PageContainer>{renderFloorPlanState(floorPlanStore)}</PageContainer>
        </Content>
        <Footer
          floorPlanStore={floorPlanStore}
          onContinue={continueNextState.bind(this, floorPlanStore)}
          onPrevious={backToPreviousState.bind(this, floorPlanStore)}
          onCancel={cancelChanges}
          onFinish={downloadCsvFile.bind(this, labelsStore)}
          onStateClick={moveToState.bind(this, floorPlanStore)}
        />
      </MainContainer>
    );
  }
}

export default Home;
