import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import colors from "../utils/colors";
import { onDesktop } from "../utils/breakpoint";
import { FiChevronRight } from "react-icons/fi";

type IProps = {
  text: string;
  to: string;
  color?: 'blue' | 'green' | 'darkgreen';
};

const Link2 = styled(Link)`
  font-size: 14px;
  letter-spacing: 0.5px;
  text-decoration: none;

  display: block;

  background-color: ${(p: IProps) => (p.color === 'darkgreen') ? colors.GREEN_DARK : colors.TP_GREEN};
  color: ${colors.WHITE};
  text-align: center;

  ${onDesktop} {
    margin-bottom: 8px;
    text-align: left;

    background-color: ${(p: IProps) => (p.color === 'green' || p.color === 'darkgreen') ? "#C6F7E8" : "#D8F1F9"};
    color: ${colors.BLACK};
    &:hover, &:active {
      background-color: ${(p: IProps) => (p.color === 'green' || p.color === 'darkgreen') ? "#90EFD1" : "#B3E3F4"};
    }
  }
`;

const Block = styled.div`
  position: relative;
  height: 50px;
  line-height: 50px;
  padding: 0 32px;
  text-decoration: none;
`;

const Floated = styled.div`
  position: absolute;
  top: 4px;
  right: 16px;
  color: ${(p: IProps) => (p.color === 'blue') ? colors.BLUE : colors.GREEN_DARK};
  display: none;
  ${onDesktop} {
    display: block;
  }
`;

const TextBlockLink: React.FunctionComponent<IProps> = (props: IProps) =>
  <Link2 {...props}>
    <Block {...props}>
      <Floated {...props}>
        <FiChevronRight size={18} />
      </Floated>
      {props.text}
    </Block>
  </Link2>;

export default TextBlockLink;