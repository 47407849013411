import React from "react";
import { Provider, MobXProviderContext } from "mobx-react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import Login from "./containers/Login";

import FloorPlanNav from "./containers/Navigation/FloorplanNavigation";
import MoveNav from "./containers/Navigation/MoveNavigation";
import StoresComponent from "./stores/StoresComponent";
import { MoveStore } from "./stores/move";
import { ApplicationStore } from "./stores/application";

type IProps = RouteComponentProps & {
  moveStore?: MoveStore;
}

const AppWithState: React.FunctionComponent<IProps> = (props: IProps) => {
  const { applicationStore } = React.useContext<{ applicationStore: ApplicationStore }>(MobXProviderContext);
  const { isAuthorized } = applicationStore;

  const loginPageWithState = {
    pathname: '/login',
    state: {
      nextPath: props.location.pathname,
    },
  };

  const logout = () => {
    applicationStore.clearPassword();
    return <Redirect to="/move/info" />;
  };

  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      {!isAuthorized && <Redirect to={loginPageWithState} />}
      <Route exact path="/logout" render={logout} />

      <Route exact path="/">
        <Redirect to="/move/info" />
      </Route>

      <Route path="/move" component={MoveNav} />
      <Route path="/floorplan" component={FloorPlanNav} />
    </Switch>
  )
};

const AppWithStateAndRouter = withRouter(AppWithState);

class EmployeeUIApp extends StoresComponent {

  render() {
    return (
      <Provider {...this.stores}>
        <Router>
          <AppWithStateAndRouter />
        </Router>
      </Provider>
    );
  }
}

export default EmployeeUIApp;
