import React from "react";
import styled from "styled-components";
import colors from "../utils/colors";
import { FloorPlanEditState } from "../types/floorplan";
import { Theme } from "../utils/themes";

interface CircleStyleProps {
  theme: Theme;
  selected?: boolean;
}

const RowOfStates = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const State = styled.div`
  width: 72px;
  margin-top: -6px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StateContainer = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

const StateName = styled.p`
  color: ${props => props.theme.primaryColorLight || colors.TEXT_DEFAULT};
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 14px;
  margin: 0; // Needs to be here because of clashing Active Admin styles
  text-align: center;
  text-transform: capitalize;
`;

const Circle = styled.div<CircleStyleProps>`
  z-index: 2;
  margin-top: 4px;
  background: ${props =>
    props.selected ? props.theme.primaryColorLight || colors.GREEN_DARK : colors.WHITE};
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.primaryColorLight || colors.GREEN_DARK};
`;

const Line = styled.div`
  margin: -9px auto 0 auto;
  background: ${props => props.theme.primaryColorLight || colors.GREEN_DARK};
  height: 1px;
  width: calc(72px * 3);
`;

interface IProps {
  editState: FloorPlanEditState;
  onStateClick: (state: FloorPlanEditState) => any;
}

const StateDefiner: React.FunctionComponent<IProps> = (props: IProps) => {
  const { editState, onStateClick } = props;

  const isSelected = (state: string) => {
    if (state === editState) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <RowOfStates>
        <State onClick={() => onStateClick(FloorPlanEditState.Upload)}>
          <StateContainer>
            <StateName>{FloorPlanEditState.Upload}</StateName>
            <Circle selected={isSelected(FloorPlanEditState.Upload)} />
          </StateContainer>
        </State>
        <State onClick={() => onStateClick(FloorPlanEditState.Mark)}>
          <StateContainer>
            <StateName>{FloorPlanEditState.Mark}</StateName>
            <Circle selected={isSelected(FloorPlanEditState.Mark)} />
          </StateContainer>
        </State>
        <State onClick={() => onStateClick(FloorPlanEditState.Connect)}>
          <StateContainer>
            <StateName>{FloorPlanEditState.Connect}</StateName>
            <Circle selected={isSelected(FloorPlanEditState.Connect)} />
          </StateContainer>
        </State>
        <State onClick={() => onStateClick(FloorPlanEditState.Finish)}>
          <StateContainer>
            <StateName>{FloorPlanEditState.Finish}</StateName>
            <Circle selected={isSelected(FloorPlanEditState.Finish)} />
          </StateContainer>
        </State>
      </RowOfStates>
      <Line />
    </div>
  );
};
export default StateDefiner;
