import StoresComponent from "./StoresComponent";
import axios from "axios";
import { Dayjs } from "dayjs";
import { apiURL, apiAxiosConfig, handleAsyncErrors, expect, simpleLazyObservable } from "../utils/api";
import { action, observable } from "mobx";
import dayjs from "dayjs";
import { sortBy } from "lodash";
import i18n, { locales, Locale, localeByIso } from "../i18n";
import constants from "../utils/constants";

/*
  Types: Schedule-related
*/

type AScheduleEvent = {
  date_from: string;
  date_to?: string;
  description: string;
};

export type ScheduleEvent = {
  start: Dayjs;
  end?: Dayjs;
  description: string;
};

const parseScheduleEvent = (input: AScheduleEvent) => {
  return {
    start: dayjs(input.date_from, "DD-MM-YYYY").startOf('day'),
    end: input.date_to ? dayjs(input.date_to, "DD-MM-YYYY").endOf('day') : undefined,
    description: input.description,
  } as ScheduleEvent;
}

type ScheduleEndpointResponse = {
  events: AScheduleEvent[];
}

// ----------------------------------------------------------------------------

export class MoveStore {

  private storesComponent: StoresComponent | undefined;
  constructor(sc: StoresComponent) {
    this.storesComponent = sc;
  }

  get stores() {
    return this.storesComponent!.stores;
  }

  // --------------------------------------------------------------------------

  @observable
  public locale = localeByIso(constants.PREFERRED_LANGUAGE_ISO);

  schedule = simpleLazyObservable<ScheduleEvent[]>(this, this.fetchSchedule, []);

  // --------------------------------------------------------------------------

  @action
  public setLocale(backendLocale: string) {
    this._setLocale(locales.find(l => l.backend === backendLocale));
  }

  @action
  public setNextLocale() {
    const currentIndex = locales.findIndex(l => l.iso === this.locale.iso);
    const nextIndex = (currentIndex + 1) % (locales.length);
    this._setLocale(locales[nextIndex]);
  }

  private _setLocale(locale?: Locale) {
    if (locale) {
      i18n.changeLanguage(locale.i18next);
      dayjs.locale(locale.dayjs);
      this.locale = locale;
    }
  }

  @handleAsyncErrors({ logErrorMessage: "Error in fetching move schedule" })
  @action
  private async fetchSchedule() {
    const unsortedEvents = expect(200, await axios.get<ScheduleEndpointResponse>(
        apiURL('/move/schedule.json'),
        apiAxiosConfig(this.stores)
      ))
      .data.events.map(parseScheduleEvent);
    return sortBy(unsortedEvents, [
      e => e.start.unix(),                        // First sort by start date
      e => e.end ? e.end.unix() : e.start.unix(), // and if those are same, try to sort by end date
    ]);
  }

  // No safeguard decorators here, because we actually want this method to
  // throw in case of errors
  public async getMoveOrThrow(password?: string) {
    return await axios.get(
      apiURL('/move.json'),
      apiAxiosConfig(this.stores, {}, password)
    );
  }

}