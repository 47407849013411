import React, { Component } from "react";
import styled from "styled-components";
import { observer, inject } from "mobx-react";
import { FloorPlanStore } from "../stores/floorplan";
import colors from "../utils/colors";
import { Table, TableCell, TableHead, TableHeadCell, TableRow, TableBody } from "./BrandTable";
import {
  Floor,
  Owner,
  SpaceTag,
  TableTag,
  isSpaceTag,
  isTableTag,
  Option,
} from "../types/floorplan";
import { BrandButtonSecondary, BrandButtonTertiary } from "./BrandButton";
import constants from "../utils/constants";
import ImageOptions from "./ImageOptions";
import { FloorImageContainer } from "./FloorImageContainer";
import FloorImageContent from "./FloorImageContent";
import EmployeeLists from "./EmployeeLists";
import { Subtitle, Description, TextArea } from "./BrandText";
import { ScrollableContainer, SCROLLBAR_WIDTH_CSS } from "./ScrollableContainer";
import { SpacesStore } from "../stores/spaces";

interface ScrollableRightCssProps {
  isSpaceSelected: boolean;
}

const FloorPlanContainer = styled.div`
  height: 100%;
`;

const PlanDivider = styled.div`
  margin-top: 30px;
  background-color: ${props => props.theme.supportingColorLight || colors.TP_TURQUOISE_DARK};
  height: 1px;
  width: 100%;
`;

const ContentWrapper = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: auto 360px;
  grid-template-rows: 60px auto;
  grid-template-areas:
    "content-1 content-3"
    "content-2 content-3";
`;

const GriddedImageOptions = styled.div`
  grid-area: content-1;
  margin: 0 16px;
`;

const GriddedFloorImageContainer = styled(FloorImageContainer)`
  grid-area: content-2;
`;

const ScrollableRight = styled(ScrollableContainer)<ScrollableRightCssProps>`
  border-left: 1px solid ${props => props.theme.supportingColorLight || colors.TP_TURQUOISE_DARK};
  padding: 24px ${SCROLLBAR_WIDTH_CSS};
  grid-row: ${props => (props.isSpaceSelected ? "2" : "1 / -1")};
`;

const UploadButton = styled(BrandButtonTertiary)`
  width: 280px;
`;

const SidebarPaddingContainer = styled.div`
  margin: 0px 10px;
`;

const UploadMoreNamesWrapper = styled.div`
  border-left: 1px solid ${props => props.theme.supportingColorLight || colors.TP_TURQUOISE_DARK};
`;

const UploadMoreNamesButton = styled(BrandButtonSecondary)`
  width: 200px;
  margin: 22px 18px;
  text-transform: uppercase;
`;

interface IProps {
  floorPlanStore: FloorPlanStore;
  spacesStore?: SpacesStore;
}

interface IState {
  selectedOwner: Owner | null;
  zoomLevel: number;
  names: string;
  submitNames: string[];
}

@inject("spacesStore")
@observer
class FloorPlanConnect extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      selectedOwner: null,
      zoomLevel: constants.ZOOM_LEVEL_DEFAULT,
      names: "",
      submitNames: [],
    };

    this.handleRowSelection = this.handleRowSelection.bind(this);
    this.handleImageClick = this.handleImageClick.bind(this);
    this.handleMarkerClick = this.handleMarkerClick.bind(this);
    this.updateFloor = this.updateFloor.bind(this);
    this.setZoomLevel = this.setZoomLevel.bind(this);
    this.handleTextAreaChange = this.handleTextAreaChange.bind(this);
    this.uploadNames = this.uploadNames.bind(this);
  }

  floorImg: any = React.createRef();

  updateFloor = (selectedFloor: Floor) => {
    this.props.floorPlanStore.setCurrentFloor(selectedFloor);
  };

  setZoomLevel = (level: number) => {
    this.setState({ zoomLevel: level });
  };

  handleImageClick = () => {
    this.props.floorPlanStore.setSpaceSelection(null);
  };

  handleMarkerClick = (event: React.MouseEvent, tag: SpaceTag | TableTag) => {
    if (isSpaceTag(tag)) {
      this.props.floorPlanStore.setSpaceSelection(tag);
    }
    if (isTableTag(tag)) {
      // In case we need an option to select table directly
    }
  };

  handleRowSelection = (owner: Owner) => {
    if (this.state.selectedOwner === owner) {
      this.setState({ selectedOwner: null });
    } else {
      this.setState({ selectedOwner: owner });
    }
  };

  handleTextAreaChange = (e: any) => {
    this.setState({
      names: e.target.value,
    });
  };

  handleOnUploadMoreNamesClick = () => {
    this.props.floorPlanStore.setSpaceSelection(null);
  };

  uploadNames = () => {
    const submitNames = this.state.names
      .split(/\r?\n/)
      .map(name => name.trim())
      .filter(name => name.length > 0);
    this.props.floorPlanStore.createOwner(submitNames);

    this.setState({
      names: "",
    });
  };

  // Returns a table options for specific space.
  getTableOptions = (spaceTag: SpaceTag | null): Option[] => {
    const { currentFloor } = this.props.floorPlanStore;
    if (!currentFloor || !spaceTag || !this.props.spacesStore) {
      return [];
    }

    return this.props.spacesStore.tableOptionsOnASpace(currentFloor, spaceTag, true, true);
  };

  renderUploadMoreNamesView = () => (
    <UploadMoreNamesWrapper>
      <UploadMoreNamesButton onClick={this.handleOnUploadMoreNamesClick}>
        Upload more names
      </UploadMoreNamesButton>
    </UploadMoreNamesWrapper>
  );

  renderUploadNamesView = () => (
    <div>
      <Subtitle>Add a list of people</Subtitle>
      <Description>
        Make marking the people easier by uploading a list of names to the system. Each name should
        be in the same format and separated by rows.
      </Description>
      <Table style={{ width: "280px" }}>
        <TableHead>
          <tr>
            <TableHeadCell>Names</TableHeadCell>
          </tr>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell style={{ padding: 0 }}>
              <TextArea
                id="story"
                name="story"
                value={this.state.names}
                onChange={this.handleTextAreaChange}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <UploadButton onClick={this.uploadNames}>Upload names</UploadButton>
    </div>
  );

  render() {
    const {
      currentFloor,
      tableTags,
      unAssignedOwners,
      assignedOwners,
      assignOwner,
      assignOwnerWithName,
      assignOwnerTable,
      returnOwner,
      deleteOwner,
      spaceSelection,
      spaceAssignedOwners,
    } = this.props.floorPlanStore;
    const { zoomLevel, selectedOwner } = this.state;

    // currentFloor should never be null at this point
    if (!currentFloor || !this.props.spacesStore) {
      return null;
    }

    const spaceOptions = this.props.spacesStore.spaceOptionsOnAFloor(currentFloor, true);

    return (
      <FloorPlanContainer>
        <ContentWrapper>
          <GriddedImageOptions>
            <ImageOptions onZoom={this.setZoomLevel} zoomLevel={zoomLevel} marginRight="16px" />
          </GriddedImageOptions>
          <GriddedFloorImageContainer>
            <FloorImageContent
              forwardedRef={this.floorImg}
              currentFloor={currentFloor}
              zoomLevel={zoomLevel}
              initialFit="contain"
              tableTags={tableTags}
              onImageClick={this.handleImageClick}
              onMarkerClick={this.handleMarkerClick}
            />
          </GriddedFloorImageContainer>

          {spaceSelection !== null && this.renderUploadMoreNamesView()}

          <ScrollableRight isSpaceSelected={spaceSelection !== null}>
            <SidebarPaddingContainer>
              {spaceSelection === null && this.renderUploadNamesView()}
              {spaceSelection !== null && (
                <Subtitle>Space {spaceSelection.title} selected</Subtitle>
              )}
            </SidebarPaddingContainer>
            {spaceSelection === null && <PlanDivider />}
            <EmployeeLists
              selectedOwner={selectedOwner}
              onOwnerSelect={this.handleRowSelection}
              assignedOwners={assignedOwners}
              unassignedOwners={unAssignedOwners}
              spaceAssignedOwners={spaceAssignedOwners}
              spaceOptions={spaceOptions}
              getTableOptions={this.getTableOptions}
              spaceSelected={spaceSelection}
              onSpaceSelected={assignOwner}
              onSpaceOptionSelected={assignOwnerWithName}
              onTableSelected={assignOwnerTable}
              onOwnerReturn={returnOwner}
              onOwnerRemove={deleteOwner}
            />
          </ScrollableRight>
        </ContentWrapper>
      </FloorPlanContainer>
    );
  }
}

export default FloorPlanConnect;
