import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationsEnGB from './locales/translations.en_GB.json';
import translationsNbNO from './locales/translations.nb_NO.json';
import constants from './utils/constants';
import { chain } from 'lodash';
import { isDayjs } from 'dayjs';

// Remember to add new locales to this list as well!
import 'dayjs/locale/en';
import 'dayjs/locale/nb';

export type Locale = {
  iso: string;
  i18next: string;
  backend: string;
  dayjs: string;
  translation: object;
  ui: string;
}

export const locales: Locale[] = [
  {
    iso: 'en_GB',
    i18next: 'en_GB',
    backend: 'en',
    dayjs: 'en',
    translation: translationsEnGB,
    ui: 'EN',
  },
  {
    iso: 'nb_NO',
    i18next: 'nb_NO',
    backend: 'no',
    dayjs: 'nb',
    translation: translationsNbNO,
    ui: 'NO',
  },
  {
    iso: 'debug',
    i18next: 'cimode',
    backend: 'debug',
    dayjs: 'en',
    translation: translationsEnGB,
    ui: 'DEBUG',
  },
];

export const localeByIso = (iso: string) => {
  return locales.find(l => l.iso === iso) || locales[0];
}

const localeByI18Next = (i18next = 'en_GB') => {
  return locales.find(l => l.i18next === i18next) || locales[0];
}

const resources = chain(locales)
  .keyBy('iso')
  .omit('cimode')
  .mapValues(locale => ({ translation: locale.translation }))
  .value();

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localeByIso(constants.PREFERRED_LANGUAGE_ISO).i18next,
    fallbackLng: localeByIso(constants.FALLBACK_LANGUAGE_ISO).i18next,

    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: function(value, format, i18nextLanguage) {
        if (isDayjs(value)) {
          return value.locale(localeByI18Next(i18nextLanguage).dayjs).format(format);
        }
        return value;
      }
    },
  });


export default i18n;