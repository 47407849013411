import React from "react";
import { useTranslation } from 'react-i18next';
import EmployeeUiLayout from "../../components-mobile/EmployeeUiLayout";
import Schedule from "../../components-mobile/Schedule";
import { Title } from "../../components/BrandText";

type IProps = {};

const ScheduleView: React.FunctionComponent<IProps> = (props: IProps) => {
  const { t } = useTranslation();
  return (
    <EmployeeUiLayout>
      <Title>{t('schedule.title')}</Title>
      <Schedule />
    </EmployeeUiLayout>
  );
};

export default ScheduleView;