import React from "react";
import styled from "styled-components";
import { onDesktop } from "../../utils/breakpoint";

const ArticleEditorInnerHtml = styled.div`
  .grid {
    height: 100%;
  }

  li,
  h1,
  h2,
  h3 {
    font-weight: lighter;
  }

  figure + p {
    margin-top: 1rem;
  }

  figure + p + p {
    margin-top: 0.5rem;
    font-weight: lighter;
  }

  > .grid {
    display: grid;
    margin-top: 4rem;
    grid-template-columns: repeat(4, 1fr);

    p,
    ul,
    h1,
    h2,
    h3 {
      padding: 0 2rem;
    }

    ul {
      margin: 0.5rem;
    }

    li {
      margin: 0 2rem;
    }

    ${onDesktop} {
      grid-template-columns: repeat(12, 1fr);

      p,
      ul,
      h1,
      h2,
      h3 {
        padding: 0rem;
      }
    }
  }

  .column {
    display: grid;

    h3:first-child {
      margin-top: 2rem;
    }

    p:last-child,
    ul:last-child {
      margin-bottom: 2rem;
    }
  }
`;

type IProps = {
  articleHtml: string;
};

export const ArticleEditorWrapper = ({ articleHtml }: IProps) => {
  return (
    <ArticleEditorInnerHtml
      className="arx-editor arx-reset arx-content"
      dangerouslySetInnerHTML={{ __html: articleHtml }}
    />
  );
};
