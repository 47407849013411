export interface FloorLayoutBase {
  id: number;
  floor_name: string;
  url: string;
}

export interface FloorLayout extends FloorLayoutBase {
  markers: FloorMarker[];
  filename: string;
  labelColor?: string;
  floorSize: string;
}

export type FloorMarker = {
  id?: number;
  move_layout_id?: number;
  marker_type: string;
  title: string;
  coordinates: {
    positionX: number;
    positionY: number;
    openSpace?: boolean;
    space?: string;
  };
  users?: FloorMarkerUser[];
};

export type FloorMarkerUser = {
  id: number;
  name: string;
  stickers_number: number | null;
};

export type FloorMarkerOwner = {
  id: number;
  name: string;
  stickersNumber: number | null;
  floorId: number;
  spaceId: number;
  tableId: number | null;
};

export type ScheduleEvent = {
  date_from: string;
  date_to: string | null;
  description: string;
};

export type WritableMove = {
  stickers_number?: number;
  unassigned_people?: string[];
}

export type GeneralInfo = {
  title: string;
  content: string;
};

export type ContactInfo = {
  email: string | null;
  job_title: string | null;
  name: string;
  phone: string | null;
};

export const isGeneralInfoArray = (x: GeneralInfo[] | ContactInfo): x is GeneralInfo[] => {
  return !!(x as GeneralInfo[]);
};

export const isContactInfo = (x: GeneralInfo[] | ContactInfo): x is ContactInfo => {
  return !!(x as ContactInfo).name;
};

export type FAQElement = {
  question: string;
  answer: string;
};

export type Feedback = {
  name: string;
  email: string;
  phone: string;
  feedback_type: FeedbackType;
  question: string;
};

export enum FeedbackType {
  Feedback = "feedback",
  Question = "question",
  Error = "error",
}
