import React from "react";
import styled from "styled-components";
import { FiChevronLeft } from "react-icons/fi";
import colors from "../utils/colors";

interface IProps {
  history?: any;
  onClick?: () => void;
  title: string;
  to?: string;
  isReturnButton?: boolean; // Used for detecting this component in EmployeeUIElement child rendering
}

const ReturnContainer = styled.div`
  /*
    The <FiChevronLeft> component has about 4 pixels of padding of the left
    side of the arrow. To be able to position the ReturnButton to the left
    side of the container where it is in, we adjust for that
    extra padding here.
  */
  margin-left: -4px;
  display: flex;
`;

const ReturnTitle = styled.div`
  color: ${colors.BLUE_LIGHT}
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.42px;
  line-height: 14px;
  text-transform: uppercase;
  margin-left: 8px;
`;

const ReturnButton: React.FunctionComponent<IProps> = (props: React.PropsWithChildren<IProps>) => {
  const { history, children } = props;

  /*
    props.title and props.onClick / props.to can be overriden by supplying the following URL parameters:
    - rt (return title)
    - rp (return path)
  */

  const url = new URL(window.location.href);

  const doNothing = () => {};
  const to = url.searchParams.get('rp') || props.to;
  const goTo = () => {
    history.push(to);
  }
  const onClick = (history && to && goTo) || props.onClick || doNothing;
  const title = url.searchParams.get('rt') || props.title;

  return (
    <ReturnContainer onClick={onClick}>
      <FiChevronLeft size={14} color={colors.DARK_GRAY} />
      <ReturnTitle>{title}</ReturnTitle>
      {children}
    </ReturnContainer>
  );
};

ReturnButton.defaultProps = {
  isReturnButton: true,
};

export default ReturnButton;
