import React from "react";
import styled from "styled-components";
import { FloorPlanStore } from "../stores/floorplan";
import colors from "../utils/colors";
import { observer, MobXProviderContext } from "mobx-react";
import { Table, TableCell, TableHead, TableHeadCell, TableRow } from "./BrandTable";
import { FiDelete } from "react-icons/fi";
import { Subtitle, SubtitleSmall } from "./BrandText";
import { ScrollableContainer, SCROLLBAR_WIDTH_CSS } from "./ScrollableContainer";
import { LabelsStore } from "../stores/labels";

const Container = styled(ScrollableContainer)`
  height: calc(100% - 48px);
  padding: 24px 32px;
  padding-right: calc(32px + ${SCROLLBAR_WIDTH_CSS});
`;

const PlanDescription = styled.p`
  color: ${colors.TEXT_DEFAULT};
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.42px;
  line-height: 18px;
  margin-top: 16px;
`;

const PlanInput = styled.input`
  margin-top: 8px;
  height: 32px;
  width: 122px;
  border: 1px solid ${colors.TP_TURQUOISE};
  padding: 4px;
  &:focus {
    outline: none;
  }
`;

const deleteButtonWidth = 18;
const inputPadding = 4;
const TableInput = styled.input`
  width: calc(100% - ${2*inputPadding + deleteButtonWidth}px);
  border: 0;
  padding: ${inputPadding}px;
  &:focus {
    outline: none;
  }
`;

interface IProps {
  floorPlanStore: FloorPlanStore;
}

const FloorPlanFinish: React.FunctionComponent<IProps> = observer((props: IProps) => {
  const { floorPlanStore } = props;
  const { labelsStore } = React.useContext<{ labelsStore: LabelsStore }>(MobXProviderContext);

  return (
    <Container>
      <Subtitle>Amount of labels needed</Subtitle>
      <PlanDescription>
        Below is a list of people <strong>assigned</strong> to new spaces. All that’s left is to determine how many
        label stickers each person needs for their items. You can also define a default amount of
        labels for everyone below.
      </PlanDescription>
      <SubtitleSmall>Default amount</SubtitleSmall>
      <PlanInput
        type="number"
        min="0"
        max="999"
        value={floorPlanStore.move.stickersNumber}
        onChange={e => floorPlanStore.setStickersNumberForMove(parseInt(e.target.value, 10))}
      />

      <Table>
        <TableHead>
          <tr>
            <TableHeadCell>Room Number</TableHeadCell>
            <TableHeadCell>Name</TableHeadCell>
            <TableHeadCell>Amount</TableHeadCell>
            <TableHeadCell>Company</TableHeadCell>
            <TableHeadCell>Label color</TableHeadCell>
          </tr>
        </TableHead>
        <tbody>
          {labelsStore.labels.map((label, i) => {
            return (
              <TableRow key={i}>
                <TableCell>{label.roomNumber}</TableCell>
                <TableCell>{label.name}</TableCell>
                <TableCell>
                  <TableInput
                    type="number"
                    min="0"
                    max="999"
                    placeholder={floorPlanStore.move.stickersNumber + " (default amount)"}
                    value={label.count !== null ? label.count : ""}
                    onChange={e => floorPlanStore.setStickersNumberForOwner(label.ownerKey, parseInt(e.target.value))}
                  />
                  {(label.count !== null) &&
                    <FiDelete
                      size={18}
                      color={colors.GRAY}
                      style={{ verticalAlign: "middle" }}
                      onClick={e => floorPlanStore.setStickersNumberForOwner(label.ownerKey, null)} />
                  }
                </TableCell>
                <TableCell>{label.companyName}</TableCell>
                <TableCell>{label.color}</TableCell>
              </TableRow>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
});

export default FloorPlanFinish;
