import React, { useState } from "react";
import Select from "react-select";
import styled from "styled-components";
import colors from "../utils/colors";
import { Option } from "../types/floorplan";

const customStyles = {
  option: (provided: any, state: any) => ({
    color: state.isDisabled ? colors.TP_GRAY : colors.TEXT_DEFAULT,
    padding: 8,
    "&:hover": {
      cursor: state.isDisabled ? "default" : "pointer",
    },
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    minWidth: "100%",
    minHeight: undefined,
    border: state.isFocused ? 0 : 0,
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    backgroundColor: undefined,
    "&:hover": {
      border: state.isFocused ? 0 : 2,
      borderColor: colors.TP_TURQUOISE,
    },
    "&:focus": {
      border: 2,
      borderColor: colors.TP_TURQUOISE,
    },
  }),
  indicatorSeparator: () => ({}),
  indicatorsContainer: () => ({
    height: "auto",
    paddingRight: 16,
    paddingLeft: 16,
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    padding: 0,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: colors.GRAY,
    marginLeft: 0,
    marginRight: 0,
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: 0,
    marginTop: 2,
    boxShadow: "0 2px 20px 2px rgba(151,151,151,0.3)",
    "&:hover": {
      cursor: "pointer",
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    marginLeft: 0,
    marginRight: 0,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    paddingLeft: 16,
  }),
  input: (provided: any) => ({
    ...provided,
    fontSize: 12,
    margin: 0,
  }),
};

const SelectWrapper = styled.div<any>`
  svg {
    color: ${props => props.isDisabled ? colors.TP_GRAY : props.theme.primaryColor || colors.BLUE};
  }
`;

interface IProps {
  options: any;
  onChange: (e: any) => void;
  initValue?: Option | number | null;
}

const BlueSelect: React.FunctionComponent<IProps> = (props: IProps) => {
  const { options, onChange, initValue } = props;

  const defaultInitValue: Option = {
    value: '-',
    label: '-',
    disabled: false,
  };

  const [value, setValue] = useState(initValue || (options && options[0]) || defaultInitValue);

  const handleChange = (e: any) => {
    setValue(e);
    onChange(e);
  };

  return (
    <SelectWrapper isDisabled={options === null}>
      <Select
        isDisabled={options === null}
        options={options}
        styles={customStyles}
        placeholder={null}
        onChange={handleChange}
        value={value}
        isOptionDisabled={option => option.disabled === true}
      />
    </SelectWrapper>
  );
};

export default BlueSelect;
