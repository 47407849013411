import React from "react";
import styled from "styled-components";
import colors from "../utils/colors";
import constants from "../utils/constants";
import { FloorPlanStore } from "../stores/floorplan";
import { FloorPlanEditState } from "../types/floorplan";
import StateDefiner from "./StateDefiner";
import { BrandButtonPrimary, BrandButtonSecondary, BrandButtonNegative } from "./BrandButton";

const Container = styled.footer`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: ${constants.FOOTER_HEIGHT};
  min-width: 901px;
  flex-direction: row;
  background-color: ${colors.WHITE};
  margin: 0;
  z-index: 5;
`;

const ContinueBtn = styled(BrandButtonPrimary)`
  margin-right: 10px;
`;

const PreviousBtn = styled(BrandButtonSecondary)`
  margin-left: 10px;
`;

const ExitBtn = styled(BrandButtonNegative)`
  margin-left: 10px;
`;

const ContentWrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  align-items: center;
`;
const Left = styled.div`
  min-width: 300px;
  grid-column: 1;
`;
const Right = styled.div`
  min-width: 300px;
  grid-column: 3;
  text-align: right;
`;
const Middle = styled.div`
  min-width: 300px;
  grid-column: 2;
`;

interface IProps {
  floorPlanStore: FloorPlanStore;
  onContinue: () => void;
  onPrevious: () => void;
  onCancel: () => void;
  onFinish: () => void;
  onStateClick: (state: FloorPlanEditState) => void;
}

const EditorFooter: React.FunctionComponent<IProps> = (props: IProps) => {
  const { onContinue, onPrevious, onCancel, floorPlanStore, onFinish, onStateClick } = props;

  return (
    <Container>
      <ContentWrapper>
        <Left>
          <ExitBtn onClick={() => onCancel()}>Exit</ExitBtn>
          {floorPlanStore.editState !== FloorPlanEditState.Upload && (
            <PreviousBtn onClick={() => onPrevious()}>Previous step</PreviousBtn>
          )}
        </Left>
        <Middle>
          <StateDefiner editState={floorPlanStore.editState} onStateClick={onStateClick} />
        </Middle>
        <Right>
          {floorPlanStore.editState === FloorPlanEditState.Finish ? (
            <ContinueBtn onClick={() => onFinish()}>Download csv</ContinueBtn>
          ) : (
            <ContinueBtn onClick={() => onContinue()}>Continue</ContinueBtn>
          )}
        </Right>
      </ContentWrapper>
    </Container>
  );
};
export default EditorFooter;
