import styled from "styled-components";
import colors from "../utils/colors";

export const DevelopmentInformationBadge = styled.div`
  background-color: ${colors.TURQUOISE};
  color: ${colors.WHITE};
  font-weight: bold;
  letter-spacing: 0.56px;
  margin: 3px 0;
  padding: 3px 16px;
  text-transform: uppercase;
`;