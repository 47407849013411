import React, { Component } from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import dayJS, { Dayjs } from "dayjs";
import { isNil } from "lodash";
import colors from "../utils/colors";
import { withTranslation, WithTranslation } from 'react-i18next';
import { MoveStore, ScheduleEvent } from "../stores/move";
import { TFunction } from "i18next";

const ScheduleContainer = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: -21px 0; // All event rows have padding applied, but we want to remove it from the top as well as from the bottom
`;

const EventRow = styled.tr`
  height: 70px;
`;

const DateCell = styled.td`
  width: 30%;
`;
const DateTitle = styled.p`
  color: ${colors.DARK_GRAY};
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.36px;
  line-height: 12px;
  text-transform: uppercase;
`;
const DateYear = styled.p`
  color: ${colors.GRAY};
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.36px;
  line-height: 12px;
  text-transform: uppercase;
  margin-top: 2px;
`;

const StateCell = styled.td`
  width: 50px;
`;
const StateContent = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;
const Circle = styled.div`
  height: 26px;
  width: 26px;
  border-radius: 14px;
  border: 1px solid ${colors.GREEN};
  background-color: ${colors.WHITE};
`;
const TopLine = styled.div`
  position: absolute;
  top: -22px;
  width: 1px;
  height: 22px;
  background-color: ${colors.GREEN};
`;
const BottomLine = styled.div`
  position: absolute;
  bottom: -22px;
  width: 1px;
  height: 22px;
  background-color: ${colors.GREEN};
`;

const TitleCell = styled.td`
  width: auto;
  padding: 0 16px;
  color: ${colors.DARK_GRAY};
  font-size: 12px;
  letter-spacing: 0.42px;
  line-height: 14px;
`;

const closestEventDate = (schedule: ScheduleEvent[]) => {
  // Assumption: all events have been sorted by the start date (already after they were fetched)
  const now = dayJS();
  const closestEvent = schedule.find(event => event.start.isSame(now, 'day') || event.start.isAfter(now));
  return closestEvent && closestEvent.start;
}

const eventHappensOnASpecifiedDay = (moveEvent: ScheduleEvent, comparisonDate?: Dayjs) => {
  if (!comparisonDate) {
    return false;
  }
  if (moveEvent.end) {
    // If end time is defined, comparisonDate has to be between start and end
    return (moveEvent.start.isSame(comparisonDate) || moveEvent.start.isBefore(comparisonDate)) &&
      (moveEvent.end.isAfter(comparisonDate) || moveEvent.end.isSame(comparisonDate));
  }
  // Otherwise comparisonDate needs to be same than start (ie. one same day)
  return moveEvent.start.isSame(comparisonDate, 'day');
}

const dayText = (moveEvent: ScheduleEvent, t: TFunction) => {
  if (isNil(moveEvent.end) || moveEvent.start.isSame(moveEvent.end, 'day')) {
    return t('schedule.single_day', { day: moveEvent.start });
  }
  if (moveEvent.start.isSame(moveEvent.end, 'month')) {
    return t('schedule.day_range_in_same_month', { startDay: moveEvent.start, endDay: moveEvent.end });
  }
  return t('schedule.day_range', { startDay: moveEvent.start, endDay: moveEvent.end });
}

const yearText = (moveEvent: ScheduleEvent) => {
  if (isNil(moveEvent.end) || moveEvent.start.isSame(moveEvent.end, 'year')) {
    return moveEvent.start.year();
  }
  return moveEvent.start.year() + '–' + moveEvent.end.year();
}

type IProps = WithTranslation & {
  moveStore?: MoveStore;
}

@inject("moveStore")
@observer
class Schedule extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.renderEvents = this.renderEvents.bind(this);
  }

  renderEvents = (schedule: ScheduleEvent[], t: TFunction) => {
    const comparisonDate = closestEventDate(schedule);
    return schedule.map((moveEvent, i) =>
      <EventRow key={i}>
        <DateCell>
          <DateTitle>{dayText(moveEvent, t)}</DateTitle>
          <DateYear>{yearText(moveEvent)}</DateYear>
        </DateCell>
        <StateCell>
          <StateContent>
            <Circle
              style={{
                backgroundColor:
                  eventHappensOnASpecifiedDay(moveEvent, comparisonDate) ? colors.GREEN : colors.WHITE,
              }}
            />
            {i !== 0 && <TopLine />}
            {i !== schedule.length - 1 && <BottomLine />}
          </StateContent>
        </StateCell>
        <TitleCell>{moveEvent.description}</TitleCell>
      </EventRow>
    );
  }

  render() {
    if (!this.props.moveStore) {
      return null;
    }

    const { t } = this.props;
    const lazySchedule = this.props.moveStore.schedule;
    return (<>
      {lazySchedule.current() &&
        <ScheduleContainer>
          <tbody>
            {this.renderEvents(lazySchedule.current(), t)}
          </tbody>
        </ScheduleContainer>
      }
    </>);
  }
}

export default withTranslation()(Schedule);
