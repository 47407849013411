import React from "react";
import styled from "styled-components";
import { FiTrash2 } from "react-icons/fi";
import { Option, Owner, SpaceTag } from "../types/floorplan";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableHeadCell,
  TableCellContent,
} from "./BrandTable";
import colors from "../utils/colors";
import BlueSelect from "./BlueSelect";
import CheckBox from "./BrandCheckBox";

interface IProps {
  selectedOwner: Owner | null;
  onOwnerSelect: (owner: Owner) => void;
  assignedOwners: Owner[] | null;
  unassignedOwners: Owner[] | null;
  spaceAssignedOwners: Owner[] | null;
  spaceOptions: Option[] | null;
  getTableOptions: (spaceTag: SpaceTag | null) => void;
  spaceSelected: SpaceTag | null;
  onSpaceSelected: (owner: Owner, spaceSelected: SpaceTag) => void;
  onSpaceOptionSelected: (owner: Owner, spaceTitle: string) => void;
  onTableSelected: (owner: Owner, table: string) => void;
  onOwnerReturn: (owner: Owner, spaceSelected: SpaceTag) => void;
  onOwnerRemove: (owner: Owner) => void;
}

const Container = styled.div`
  display: block;
  width: auto;
  margin-bottom: 30px;
`;

const TableContainer = styled.div`
  margin: 0px 10px;
`;

const TableTitle = styled.div`
  height: 12px;
  color: ${colors.GRAY};
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.36px;
  line-height: 12px;
  text-transform: uppercase;
  margin-top: 16px;
`;

const EmptyContentTitle = styled.p`
  padding: 8px 16px;
  color: ${props => props.theme.primaryColor || colors.BLUE};
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
`;

const SelectableRow = styled(TableRow)`
  background-color: ${(p: { isSelected?: boolean }) =>
    p.isSelected ? colors.TP_BLUE : colors.WHITE};
  &:hover {
    cursor: pointer;
  }
`;

const OwnerLabel = styled.p`
  flex: 2;
  text-align: left;
`;

const EmployeeLists: React.FunctionComponent<IProps> = (props: IProps) => {
  const {
    selectedOwner,
    onOwnerSelect,
    assignedOwners,
    unassignedOwners,
    spaceAssignedOwners,
    spaceOptions,
    getTableOptions,
    spaceSelected,
    onSpaceSelected,
    onSpaceOptionSelected,
    onTableSelected,
    onOwnerReturn,
    onOwnerRemove,
  } = props;

  const checkOwnerAssigned = (owner: Owner) => {
    if (spaceSelected) {
      onSpaceSelected(owner, spaceSelected);
    }
  };

  const selectSpace = (event: any, owner: Owner) => {
    // Set floor and space for active owner.
    onSpaceOptionSelected(owner, event.value);
  };

  const selectTable = (event: any, owner: Owner) => {
    onTableSelected(owner, event.value);
  };

  const returnOwner = (owner: Owner) => {
    if (spaceSelected) {
      onOwnerReturn(owner, spaceSelected);
    }
  };

  const getSelectedOption = (owner: Owner) => {
    if (owner.table) {
      return {
        value: owner.table.letter,
        label: owner.table.letter,
        disabled: true,
      };
    }
    return null;
  };

  const renderSpaceAssigned = () => {
    if (spaceSelected === null) {
      return null;
    }

    return (
      <TableContainer>
        <TableTitle>Space {spaceSelected ? spaceSelected.title : ""}</TableTitle>

        {spaceAssignedOwners && spaceAssignedOwners.length > 0 ? (
          <Table style={{ marginTop: 4, marginBottom: 0 }}>
            <TableHead>
              <tr>
                <TableHeadCell>Name</TableHeadCell>
                <TableHeadCell style={{ width: 80 }}>Table</TableHeadCell>
              </tr>
            </TableHead>
            <TableBody>
              {spaceAssignedOwners.map(owner => {
                return (
                  <TableRow key={owner.key}>
                    <TableCell>
                      <TableCellContent
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <CheckBox checked onChange={() => returnOwner(owner)} />
                        <OwnerLabel>{owner.name}</OwnerLabel>
                      </TableCellContent>
                    </TableCell>
                    <TableCell style={{ width: 80, padding: 0 }}>
                      <BlueSelect
                        options={getTableOptions(owner.space)}
                        onChange={value => selectTable(value, owner)}
                        initValue={getSelectedOption(owner)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <EmptyContentTitle>Select unassigned names to this space.</EmptyContentTitle>
        )}
      </TableContainer>
    );
  };

  const renderUnassigned = () => {
    return (
      <TableContainer>
        <TableTitle>Unassigned people</TableTitle>

        {unassignedOwners && unassignedOwners.length > 0 ? (
          <Table style={{ marginTop: 4, marginBottom: 0 }}>
            <TableHead>
              <tr>
                <TableHeadCell>Name</TableHeadCell>
                <TableHeadCell style={{ width: 80 }}>Space</TableHeadCell>
              </tr>
            </TableHead>
            <TableBody>
              {unassignedOwners.map(owner => {
                return (
                  <SelectableRow key={owner.key} isSelected={selectedOwner === owner}>
                    <TableCell onClick={() => onOwnerSelect(owner)}>
                      <TableCellContent
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {spaceSelected && (
                          <CheckBox checked={false} onChange={() => checkOwnerAssigned(owner)} />
                        )}
                        <OwnerLabel>{owner.name}</OwnerLabel>
                        {selectedOwner === owner && (
                          <FiTrash2
                            size={14}
                            color={colors.RED}
                            onClick={() => onOwnerRemove(owner)}
                            style={{ float: "right" }}
                          />
                        )}
                      </TableCellContent>
                    </TableCell>
                    <TableCell
                      style={{
                        padding: 0,
                        width: 80,
                      }}
                    >
                      <BlueSelect options={spaceOptions} onChange={e => selectSpace(e, owner)} />
                    </TableCell>
                  </SelectableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <EmptyContentTitle>No owners to assign</EmptyContentTitle>
        )}
      </TableContainer>
    );
  };

  const renderRestAssigned = () => {
    return (
      <TableContainer>
        <TableTitle>All assigned names</TableTitle>

        {assignedOwners && assignedOwners.length > 0 ? (
          <Table style={{ marginTop: 4, marginBottom: 0 }}>
            <TableHead>
              <tr>
                <TableHeadCell style={{ width: '60%' }}>Name</TableHeadCell>
                <TableHeadCell>F</TableHeadCell>
                <TableHeadCell>S</TableHeadCell>
                <TableHeadCell>T</TableHeadCell>
              </tr>
            </TableHead>
            <TableBody>
              {assignedOwners.map(owner => {
                return (
                  <TableRow key={owner.key} style={{ backgroundColor: colors.WHITE_WARM }}>
                    <TableCell style={{ width: '60%' }}>
                      <TableCellContent>{owner.name}</TableCellContent>
                    </TableCell>
                    <TableCell>{owner.floor && owner.floor.title}</TableCell>
                    <TableCell>{owner.space && owner.space.title}</TableCell>
                    <TableCell>{owner.table ? owner.table.letter : "-"}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <EmptyContentTitle>No names assigned yet.</EmptyContentTitle>
        )}
      </TableContainer>
    );
  };

  return (
    <Container>
      {renderSpaceAssigned()}
      {renderUnassigned()}
      {renderRestAssigned()}
    </Container>
  );
};

export default EmployeeLists;
