export default {
  LEFT_NAV_WIDTH: "220px",
  HEADER_HEIGHT: "60px",
  FOOTER_HEIGHT: "60px",

  LEFT_COLUMN_WIDTH: "900px",
  RIGHT_COLUMN_WIDTH: "450px",

  EMPLOYEE_UI_HEADER_HEIGHT: "48px",
  EMPLOYEE_UI_FOOTER_HEIGHT: "46px",
  EMPLOYEE_UI_PLAN_MARGIN: 40,

  SPACE_MODAL_MARGIN: "4px",

  SEARCHBAR_MARGIN: "16px",
  SEARCHBAR_HEIGHT: "28px",
  SIDEBAR_WIDTH: "332px",

  ZOOM_LEVEL_DEFAULT: 1,
  ZOOM_LEVEL_MIN: 0.15,
  ZOOM_LEVEL_INCREMENT: 0.15,

  MARKER_TOOL_WIDTH: 240,

  PREFERRED_LANGUAGE_ISO: "nb_NO",
  /*
    As the app has been coded in English, it is most likely that English strings are always
    available, even though they could be missing from the translations.
  */
  FALLBACK_LANGUAGE_ISO: 'en_GB',

  MAX_NUMBER_OF_SEARCH_RESULTS: 10,

  BREAKPOINT_DESKTOP: "700px",
  BREAKPOINT_DESKTOP_NAKED: 700,

  LIGHTWEIGHT_BORDER: "1px solid rgba(0, 0, 0, 0.05)",
};
