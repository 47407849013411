import React from "react";
import styled from "styled-components";
import colors from "../utils/colors";
import checkMark from "../assets/checkmark.svg";

const CustomInput = styled.input.attrs({ type: "checkbox" })`
  height: 16px;
  width: 16px;
  border: 1px solid ${colors.TURQUOISE};
  -webkit-appearance: none;
  background-color: ${colors.WHITE};
  margin-right: 20px;
  &:checked {
    background-color: ${colors.TP_TURQUOISE};
    background-image: url(${checkMark});
  }
`;

interface IProps {
  checked: boolean;
  onChange: () => void;
}

const CheckBox: React.FunctionComponent<IProps> = (props: IProps) => {
  const { checked, onChange } = props;
  return <CustomInput checked={checked} onChange={onChange} />;
};

export default CheckBox;
