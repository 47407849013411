import React from "react";
import Select from "react-select";
import styled from "styled-components";
import colors from "../utils/colors";
import { Option } from "../types/floorplan";

const customStyles = {
  option: () => ({
    color: colors.TEXT_DEFAULT,
    padding: 8,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    minWidth: 120,
    minHeight: undefined,
    border: state.isFocused ? 0 : 0,
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: state.isFocused ? 0 : 2,
      borderColor: colors.TP_TURQUOISE,
    },
    "&:focus": {
      border: 2,
      borderColor: colors.TP_TURQUOISE,
    },
  }),
  indicatorSeparator: () => ({}),
  indicatorsContainer: () => ({
    height: "auto",
    paddingRight: 16,
    paddingLeft: 16,
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    padding: 0,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: colors.GRAY,
    marginLeft: 0,
    marginRight: 0,
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: 0,
    marginTop: 2,
    boxShadow: "0 2px 20px 2px rgba(151,151,151,0.3)",
    zIndex: 100,
    "&:hover": {
      cursor: "pointer",
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    marginLeft: 0,
    marginRight: 0,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    paddingLeft: 16,
  }),
  input: (provided: any) => ({
    ...provided,
    fontSize: 12,
    margin: 0,
  }),
};

const SelectWrapper = styled.div`
  svg {
    color: ${props => props.theme.primaryColor || colors.TURQUOISE};
  }
`;

interface IProps {
  options: any;
  onChange?: (e: any) => void;
  value?: Option | number | null;
}

const BrandSelect: React.FunctionComponent<IProps> = (props: IProps) => {
  const { options, onChange, value } = props;

  return (
    <SelectWrapper>
      <Select
        options={options}
        styles={customStyles}
        // placeholder={"None"}
        onChange={onChange}
        value={value}
      />
    </SelectWrapper>
  );
};

export default BrandSelect;
