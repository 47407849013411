import StoresComponent from "./StoresComponent";
import { observable, computed, action, autorun } from "mobx";
import { defer } from "lodash";

export enum ApplicationMode {
  Admin = "ADMIN_UI_MODE",
  Employee = "EMPLOYEE_UI_MODE",
};

const LOCAL_STORAGE_PASSWORD_KEY = "password";

export class ApplicationStore {

  private storesComponent: StoresComponent | undefined;
  constructor(sc: StoresComponent) {
    this.storesComponent = sc;
    defer(this.initializeSideEffects);
  }

  get stores() {
    return this.storesComponent!.stores;
  }

  // --------------------------------------------------------------------------

  /*
    As this store (and almost the whole application) can be used in multiple
    different modes: floorplanner injected as a part of the Admin UI, or
    just employee data viewed via the Employee UI, it is sometimes important
    to know in which mode we are. Different parts of the application require
    different kind of data, and having applicationStore.mode available, we
    are able to drop some API calls which are not necessary.
  */
  @observable
  public mode = ApplicationMode.Employee;

  @observable
  public subdomain = process.env.REACT_APP_SUBDOMAIN
    ? process.env.REACT_APP_SUBDOMAIN
    : window.location.host.split(".")[0];

  @computed
  get subdomainHasBeenSet() {
    /*
      "admin" is not a valid value for a subdomain, but because of timing issues,
      it might be that some API calls would get triggered before the actual subdomain
      information is read from the admin UI injection HTML attributes. This check
      prevents those calls from going through.
    */
    return this.subdomain !== undefined && this.subdomain !== "admin";
  }

  @observable
  public password = window.localStorage.getItem(LOCAL_STORAGE_PASSWORD_KEY) || "";

  @computed
  get isAuthorized() {
    /*
      Admin mode does not necessarily need to have a password set, because it uses
      the normal session cookie to authenticate the user, instead of the
      Authorization header. We can assume that (at least in production) floorplanner
      is only loaded inside of the Admin UI, so we can assume that at that point
      the cookie already exists. For local development we can just visit the /login
      route before accessing the /floorplan/cpm route.
    */
    return this.subdomainHasBeenSet && (this.mode === ApplicationMode.Admin || this.password !== "");
  }

  // --------------------------------------------------------------------------

  private initializeSideEffects = () => {
    autorun(() => {
      this.initializeLegacyFloorPlanStoreThings();
    });
  }

  // --------------------------------------------------------------------------

  @action
  public async setPasswordOrThrowIfInvalid(password: string) {
    await this.stores.moveStore.getMoveOrThrow(password);
    window.localStorage.setItem(LOCAL_STORAGE_PASSWORD_KEY, password);
    this.password = password;
  }

  @action
  public async clearPassword() {
    window.localStorage.removeItem(LOCAL_STORAGE_PASSWORD_KEY);
    this.password = "";
  }

  @action
  public enableAdminMode(subdomain?: string) {
    this.mode = ApplicationMode.Admin;
    if (subdomain) {
      this.subdomain = subdomain;
    }
    this.initializeLegacyFloorPlanStoreThings();
  }

  // --------------------------------------------------------------------------

  private async initializeLegacyFloorPlanStoreThings() {
    if (this.isAuthorized) {
      await this.stores.floorPlanStore.fetchMove();
      await this.stores.floorPlanStore.refreshLayouts();
    }
  }
}