import React from "react";
import styled from "styled-components";
import { observer, inject } from "mobx-react";
import EmployeeUiLayout from "../../components-mobile/EmployeeUiLayout";
import constants from "../../utils/constants";
import Zoom from "../../components-shared/Zoom";
import TwinButton from "../../components-mobile/TwinButtonMobile";
import { InjectedFloorPlanStore, FloorPlanStore } from "../../stores/floorplan";
import { Floor, SpaceTag, TableTag } from "../../types/floorplan";
import FloorImageContent from "../../components/FloorImageContent";
import { RouteComponentProps } from "react-router";

const FixedLayout = styled(EmployeeUiLayout)`
  overflow-y: hidden;
`;


const FloorBtnContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 16px;
`;

const ZoomBtnContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 16px;
`;

// Area where user is able to move floor plan.
const BackgroundScopeContainer = styled.div`
  position: fixed;
  top: ${constants.EMPLOYEE_UI_HEADER_HEIGHT};
  bottom: ${constants.EMPLOYEE_UI_FOOTER_HEIGHT};
  left: 0;
  right: 0;
  overflow: scroll;
`;

const FloorPlanContainer = styled.div`
  position: relative;
  height: 100%;
  height: 100%;
`;

interface IState {
  zoomLevel: number;
  selectedSpace: SpaceTag | null;
}

type IProps = RouteComponentProps<any> & {
  floorPlanStore?: FloorPlanStore;
}

@inject("floorPlanStore")
@observer
class FloorPlan extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      zoomLevel: constants.ZOOM_LEVEL_DEFAULT,
      selectedSpace: null,
    };

    this.onTwinButtonClick = this.onTwinButtonClick.bind(this);
    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
  }

  get injected() {
    return {
      floorPlanStore: this.props.floorPlanStore,
    } as InjectedFloorPlanStore;
  }

  floorImg: any = React.createRef();

  onTwinButtonClick = (selectedFloor: Floor) => {
    // Both of these are needed, otherwise one unnecessary render is caused.
    this.injected.floorPlanStore.setCurrentFloor(selectedFloor);
    this.props.history.push("/move/floor/" + selectedFloor.id);
  };

  onMarkerClick = (e: React.MouseEvent | React.TouchEvent, tag: SpaceTag | TableTag) => {
    const title = (tag as SpaceTag).title || (tag as TableTag).letter;
    const { currentFloor } = this.injected.floorPlanStore;
    if (currentFloor) {
      const space = currentFloor.spaces.find(s => s.title === title);
      if (space) {
        this.props.history.push(`/move/floor/${currentFloor.id}/space-popup/${space.id}`);
      }
    }
  };

  onModalClose = () => {
    this.setState({ selectedSpace: null });
  };

  render() {
    const { floors, currentFloor, tableTags } = this.injected.floorPlanStore;

    return (
      <FixedLayout>
        <BackgroundScopeContainer>
          <FloorPlanContainer>
            {currentFloor && (
              <FloorImageContent
                forwardedRef={this.floorImg}
                currentFloor={currentFloor}
                zoomLevel={this.state.zoomLevel}
                initialFit="height"
                tableTags={tableTags}
                imageMargin={constants.EMPLOYEE_UI_PLAN_MARGIN}
                onMarkerClick={this.onMarkerClick}
                onImageClick={(e: React.MouseEvent) => {}}
              />
            )}
          </FloorPlanContainer>
        </BackgroundScopeContainer>
        <FloorBtnContainer>
          {currentFloor && (
            <TwinButton
              floors={floors}
              onClick={floor => this.onTwinButtonClick(floor)}
              currentFloor={currentFloor}
            />
          )}
        </FloorBtnContainer>
        <ZoomBtnContainer>
          <Zoom
            zoomLevel={this.state.zoomLevel}
            onZoom={level => this.setState({ zoomLevel: level })}
            onMobile
          />
        </ZoomBtnContainer>
      </FixedLayout>
    );
  }
}

export default FloorPlan;
