import StoresComponent from "./StoresComponent";
import { Floor, FloorSize, Option, Owner, SpaceTag, TableTag } from "../types/floorplan";
import { isNil, some } from "lodash";

const personSitsOnThisTable = (person: Owner, floor: Floor, space: SpaceTag, table: TableTag) => {
  return person.floor && person.floor.title === floor.title &&
    person.space && person.space.title === space.title &&
    person.table && person.table.letter === table.letter;
}

const someoneSitsOnThisTable = (owners: Owner[], floor: Floor, space: SpaceTag, table: TableTag) => {
  return some(owners, owner => personSitsOnThisTable(owner, floor, space, table));
}

export class SpacesStore {

  private storesComponent: StoresComponent | undefined;
  constructor(sc: StoresComponent) {
    this.storesComponent = sc;
  }

  get stores() {
    return this.storesComponent!.stores;
  }

  // --------------------------------------------------------------------------

  public spaceOptionsOnAFloor(floor: Floor, includeEmpty = false) {
    const spaceTitles = floor.spaces.map(space => space.title);
    const allTitles = includeEmpty ? ['-'].concat(spaceTitles) : spaceTitles;
    return (allTitles).map(title => ({
      value: title,
      label: title,
      disabled: false,
    }) as Option);
  }

  public tableOptionsOnASpace(floor: Floor, space: SpaceTag, includeEmpty = false, disableReserved = false) {
    const { assignedOwners } = this.stores.floorPlanStore;
    const tables = space.tables;
    const tableOptions = tables.map(table => ({
      value: table.letter,
      label: table.letter,
      disabled: disableReserved && someoneSitsOnThisTable(assignedOwners, floor, space, table),
    }) as Option);
    const emptyOptions = [{
      value: '-',
      label: '-',
      disabled: false,
    } as Option];
    return includeEmpty ? emptyOptions.concat(tableOptions) : tableOptions;
  }

  public suggestSpaceNumber(floor: Floor): number {
    const reservedNumbers = floor.spaces
      .map(space => parseInt(space.title, 10))
      .filter(spaceNumber => !isNil(spaceNumber));

    if (reservedNumbers.length === 0) {
      // add initial suggestions based on the floor size
      const floorSize = floor.floorSize === FloorSize.SMALL ? 100 : 1000;
      const initialSpaceSuggestion = parseInt(floor.title, 10) * floorSize;

      reservedNumbers.push(initialSpaceSuggestion);
    }

    return Math.max(...reservedNumbers) + 1;
  }

  public suggestTableLetter(tables: TableTag[]): string {
    const reservedLetters = tables
      .map(t => t.letter.toUpperCase())
      .filter(l => l.length === 1)
      .map(letter => letter.replace(/[^A-ZÆØÅ]/, ''))
      .filter(l => l.length === 1)
      .sort((a, b) => a.charCodeAt(0) - b.charCodeAt(0));

    if (reservedLetters.length === 0) {
      return "A";
    }
    const lastLetter = reservedLetters[reservedLetters.length - 1];
    switch (lastLetter) {
      case "Z":
        return "Æ";
      case "Æ":
        return "Ø";
      case "Ø":
        return "Å";
      case "Å":
        return "";
      default:
        return String.fromCharCode(lastLetter.charCodeAt(0) + 1);
    }
  }

}
