import React from "react";
import styled from "styled-components";
import { SpaceTag } from "../types/floorplan";
import colors from "../utils/colors";

interface IProps {
  tag: SpaceTag;
  onClick: (event: React.MouseEvent) => void;
  zoom: number;
  onMobile?: boolean;
}

const TagContainer = styled.div`
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  z-index: 2;
  &:hover {
    cursor: pointer;
  }
`;

const Space = styled.div`
  background-color: ${props => props.theme.primaryColorLight || colors.GREEN};
  height: 36px;
  width: 40px;
  -webkit-clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
  clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
  display: inline-block;
`;

const ClosedSpace = styled.div`
  background-color: ${props => props.theme.primaryColor || colors.BLUE};
  height: 36px;
  width: 40px;
  -webkit-clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
  clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
  display: inline-block;
  padding: 3px;
`;

const Title = styled.p`
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.4px;
  line-height: 36px;
  color: ${colors.WHITE};
  &:hover {
    cursor: pointer;
  }
  padding-top: 2px;
`;

const Group = styled.div`
  display: inline-block;
  left: 28px;
  top: 2px;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background-color: ${colors.BLUE};
  position: fixed;
  z-index: 3;
`;

const GroupSizeNumber = styled.p`
  color: ${colors.WHITE};
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.3px;
  line-height: 16px;
  text-align: center;
`;

const SpaceMarker: React.FunctionComponent<IProps> = (props: IProps) => {
  const { tag, onClick, zoom, onMobile } = props;

  return (
    <TagContainer
      style={{
        top: onMobile ? `${tag.positionY * zoom}%` : `${tag.positionY}%`,
        left: onMobile ? `${tag.positionX}%` : `${tag.positionX * zoom}%`,
      }}
      onClick={event => onClick(event)}
    >
      {tag.openSpace ? (
        <>
          <Space>
            <Title>{tag.title}</Title>
          </Space>
          {tag.tables.length > 0 && (
            <Group>
              <GroupSizeNumber>{tag.tables.length}</GroupSizeNumber>
            </Group>
          )}
        </>
      ) : (
        <>
          <ClosedSpace>
            <Space>
              <Title>{tag.title}</Title>
            </Space>
          </ClosedSpace>
          {tag.tables.length > 0 && (
            <Group>
              <GroupSizeNumber>{tag.tables.length}</GroupSizeNumber>
            </Group>
          )}
        </>
      )}
    </TagContainer>
  );
};
export default SpaceMarker;
