import React from "react";
import styled from "styled-components";
import colors from "../utils/colors";
import constants from "../utils/constants";
import logo from "../assets/relokator.png";
import { FloorPlanStore } from "../stores/floorplan";
import { MobXProviderContext, observer } from 'mobx-react';
import { DevelopmentInformationBadge } from "./DevelopmentInformationBadge";
import { MoveStore } from "../stores/move";
import MainNavigationElements from "./MainNavigationElements";
import { onDesktop } from "../utils/breakpoint";
import { ApplicationStore } from "../stores/application";

type IProps = {
  lightWeightBorder?: boolean;
}

const NavHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${constants.EMPLOYEE_UI_HEADER_HEIGHT};

  padding: 0 32px;
  display: flex;
  align-items: center;

  background-color: ${colors.WHITE};
  border-bottom: ${(p: IProps) => p.lightWeightBorder ? constants.LIGHTWEIGHT_BORDER : 0};
  z-index: 100;

  &, a, a:hover, a:active {
    color: ${colors.BLUE};
  }
`;

const Left = styled.div`
  flex: 1 1 150px;
`;
const Center = styled.div`
  display: none;
  ${onDesktop} {
    display: block;
  }
  height: ${constants.EMPLOYEE_UI_HEADER_HEIGHT};
  flex: 1 1 600px;
`;
const Right = styled.div`
  flex: 1 1 150px;
  text-align: right;
`;

const Logo = styled.img`
  display: inline-block;
  height: 13px;
  width: 97px;
`;

const ClientName = styled.p`
  color: ${colors.DARK_GRAY};
  font-size: 14px;
  font-weight: 300;
  letter-spacing: -0.16px;
  line-height: 14px;
  padding-top: 2px;
`;

const LanguageIndicator = styled(DevelopmentInformationBadge)`
  position: absolute;
  top: 4px;
  left: 4px;
  width: 15%;
  max-width: 100px;
  margin: 0;
  text-align: center;
  z-index: 900;
`;

const languageTesting = () => process.env.REACT_APP_LANGUAGE_TESTING === "true";

const changeLanguage = (moveStore: MoveStore) => {
  if (languageTesting()) {
    moveStore.setNextLocale();
  }
}

const Header: React.FunctionComponent<IProps> = observer((props: IProps) => {
  const { applicationStore, floorPlanStore, moveStore } = React.useContext<{ applicationStore: ApplicationStore, floorPlanStore: FloorPlanStore, moveStore: MoveStore }>(MobXProviderContext);
  const { isAuthorized } = applicationStore;
  const titleText = floorPlanStore.move.company.name;
  const uiText = moveStore.locale.ui;

  return (<>
    {languageTesting() &&
      <LanguageIndicator onClick={() => changeLanguage(moveStore)}>
        {uiText}
      </LanguageIndicator>}
    <NavHeader {...props}>
      <Left>
        <Logo src={logo} />
      </Left>
      <Center>
        {isAuthorized && <MainNavigationElements />}
      </Center>
      <Right>
        {titleText && titleText.length > 0 && <ClientName>{titleText}</ClientName>}
      </Right>
    </NavHeader>
  </>);
});
export default Header;
