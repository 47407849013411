import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";
import { FiArrowRight } from "react-icons/fi";
import { inject } from "mobx-react";
import Header from "../components-mobile/Header";
import colors from "../utils/colors";
import { BrandButtonPrimary } from "../components/BrandButton";
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { DevelopmentInformationBadge } from "../components-mobile/DevelopmentInformationBadge";
import { get } from "lodash";
import { handleAsyncErrors } from "../utils/api";
import { ApplicationStore } from "../stores/application";

const MainContainer = styled.div`
  height: 100%;
`;

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DefinitionText = styled.p`
  height: 72px;
  width: 90%;
  color: ${colors.DARK_GRAY};
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.7px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
`;

const InputContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const PasswordField = styled.input`
  height: 30px;
  width: 150px;
  padding: 4px;
  border: 1px solid ${colors.TP_TURQUOISE};
  &:focus {
    outline: none;
  }
  color: ${colors.DARK_GRAY};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.46px;
  line-height: 16px;
`;

const PasswordEnterBtn = styled(BrandButtonPrimary)`
  margin-left: 16px;
  line-height: 1px;
`;

interface IState {
  password: string;
}

type IProps = RouteComponentProps & WithTranslation & {
  applicationStore?: ApplicationStore;
};

@inject("applicationStore")
class Login extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      password: "",
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
  }

  onPasswordChange = (password: string) => {
    this.setState({
      password,
    });
  };

  @handleAsyncErrors({ logErrorMessage: "Wrong password" })
  public async validatePassword() {
    if (this.props.applicationStore) {
      await this.props.applicationStore.setPasswordOrThrowIfInvalid(this.state.password);
      const nextPath = get(this, "props.history.location.state.nextPath") || '/move/info';
      this.props.history.push(nextPath);
    }
  };

  onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    await this.validatePassword();
  }

  environmentText(t: any) {
    if (window.location.host.indexOf("relokator.info") !== -1) {
      return;
    }
    if (window.location.host.indexOf("relokator-staging") !== -1) {
      return t('environment.staging');
    }
    return t('environment.development');
  }

  render() {
    const { t } = this.props;
    return (
      <MainContainer>
        <Header lightWeightBorder={true} />

        <Content>
          <DefinitionText>
            <Trans i18nKey="login.title" />
          </DefinitionText>
          {this.environmentText(t) &&
            <DevelopmentInformationBadge>
              {this.environmentText(t)}
            </DevelopmentInformationBadge>
          }
          {process.env.REACT_APP_SUBDOMAIN &&
            <DevelopmentInformationBadge>
              {t('environment.forced_subdomain', { subdomain: process.env.REACT_APP_SUBDOMAIN })}
            </DevelopmentInformationBadge>
          }
          <form>
            <InputContainer>
              <PasswordField
                type="text"
                value={this.state.password}
                onChange={e => this.onPasswordChange(e.target.value)}
                placeholder={t('login.password.placeholder')}
                autoFocus
              />
              <PasswordEnterBtn onClick={this.onSubmit} type="submit">
                <FiArrowRight size={20} color={colors.WHITE} />
              </PasswordEnterBtn>
            </InputContainer>
          </form>
        </Content>
      </MainContainer>
    );
  }
}

export default withTranslation()(withRouter(Login));
