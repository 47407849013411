import React from "react";
import styled from "styled-components";
import colors from "../utils/colors";
import { ContactInfo, GeneralInfo, isGeneralInfoArray, isContactInfo } from "../types/api";
import { InfoType } from "../types/move";
import { onDesktop } from "../utils/breakpoint";

interface IInfoTitle {
  hasTopMargin?: boolean;
}

const Card = styled.div`
  min-width: 200px;
  padding: 26px;
  border-radius: 10px;
  background-color: ${colors.TP_TURQUOISE_DARK};
  ${onDesktop} {
    background-color: #d8f0f9;
    border-radius: 0;
    padding: 32px;
  }
`;

const InfoTitle = styled.p<IInfoTitle>`
  height: 12px;
  color: ${colors.BLUE};
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.36px;
  line-height: 12px;
  text-transform: uppercase;

  ${props => props.hasTopMargin && "margin-top: 18px"}
`;

const InfoText = styled.p`
  margin-top: 4px;
  height: 14px;
  color: ${colors.DARK_GRAY};
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 14px;
`;

interface IProps {
  infoType: InfoType;
  infoData: GeneralInfo[] | ContactInfo;
}

const InfoCard: React.FunctionComponent<IProps> = (props: IProps) => {
  const { infoType, infoData } = props;

  const renderGeneralInfo = (infos: GeneralInfo[]) =>
    infos.map((info, idx) => (
      <>
        <InfoTitle hasTopMargin={idx > 0}>{info.title}</InfoTitle>
        <InfoText>{info.content === "" ? "-" : info.content}</InfoText>
      </>
    ));

  const renderContactInfo = (contactInfo: ContactInfo) => (
    <>
      <InfoTitle>{contactInfo.job_title !== "" ? contactInfo.job_title : "Contact"}</InfoTitle>
      <InfoText>{contactInfo.name}</InfoText>
      <InfoTitle hasTopMargin>Phone</InfoTitle>
      <InfoText>{contactInfo.phone}</InfoText>
      <InfoTitle hasTopMargin>Email</InfoTitle>
      <InfoText>{contactInfo.email}</InfoText>
    </>
  );

  return (
    <Card>
      {infoType === InfoType.General && isGeneralInfoArray(infoData) && renderGeneralInfo(infoData)}
      {infoType === InfoType.Contact && isContactInfo(infoData) && renderContactInfo(infoData)}
    </Card>
  );
};
export default InfoCard;
