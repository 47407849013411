import React from "react";
import styled from "styled-components";
import { FiX } from "react-icons/fi";
import colors from "../utils/colors";
import constants from "../utils/constants";
import { Owner } from "../types/floorplan";
import { RouteComponentProps } from "react-router";
import { observer } from "mobx-react";
import EmployeeUiLayout from "./EmployeeUiLayout";
import ReturnButton from "./ReturnButton";
import { onDesktop } from "../utils/breakpoint";

const SpaceAssignsPopupContainer = styled.div`
  position: absolute;
  bottom: calc(${constants.EMPLOYEE_UI_FOOTER_HEIGHT} + ${constants.SPACE_MODAL_MARGIN});
  left: ${constants.SPACE_MODAL_MARGIN};
  right: ${constants.SPACE_MODAL_MARGIN};
  max-height: 36%;
  padding: 16px;
  border-radius: 2px;
  background-color: ${colors.WHITE}
  box-shadow: 0 2px 20px 2px ${colors.TP_GRAY};
  overflow-y scroll;

  ${onDesktop} {
    top: ${constants.EMPLOYEE_UI_HEADER_HEIGHT};
    left: 0;
    right: auto;
    bottom: 0;
    max-height: none;
    width: calc(${constants.SIDEBAR_WIDTH} - 2*${constants.SEARCHBAR_MARGIN});
    padding-top: calc(${constants.SEARCHBAR_HEIGHT} + 2*${constants.SEARCHBAR_MARGIN});
  }
`;

const Header = styled.div`
  display: inline-block;
  color: ${colors.GREEN_DARK}
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  line-height: 24px;
`;

const CloseIcon = styled(FiX)`
  float: right;
`;

const Subheader = styled.p`
  color: ${colors.DARK_GRAY}
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 17px;
`;

const NamesTable = styled.table`
  width: 100%;
  margin: 16px 0 0 0;
  border-spacing: 0;
  border-collapse: collapse;
`;
const NamesTableHead = styled.thead`
  color: ${colors.GRAY}
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.36px;
  line-height: 24px;
  text-transform: uppercase;
`;
const NamesTableBody = styled.tbody`
  color: ${colors.DARK_GRAY}
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.42px;
  line-height: 24px;
`;

const NamesTableRow = styled.tr`
  display: flex;
`;

const NamesTableCellLeft = styled.th`
  flex: 2;
  overflow: hidden;
  text-align: left;
`;
const NamesTableCellRight = styled.th`
  flex: 1;
  text-align: right;
`;

const NamesTableCellContent = styled.div`
  width: 100%;
  height: auto;
  text-align: left;
`;

type IProps = RouteComponentProps<any> & {
  people: Owner[];
  title: string;
  subtitle: string;
  leftSideTitle: string;
  rightSideTitle: string;
  getRightSideText: (person: Owner) => string;
  backButtonText?: string;
  onCloseGoTo: string;
  onClickGoTo?: string;
  getOnPersonClickGoTo?: (person: Owner) => string | null;
  popup: boolean;
}

const PeopleModal: React.FunctionComponent<IProps> = observer((props: IProps) => {
  const { people, onCloseGoTo, onClickGoTo, popup, title, subtitle, leftSideTitle, rightSideTitle, getRightSideText, getOnPersonClickGoTo, backButtonText } = props;

  const onClickHandler = onClickGoTo ? () => props.history.push(onClickGoTo) : () => {};
  const onCloseHandler = (e: React.MouseEvent) => {
    props.history.push(onCloseGoTo);
    e.stopPropagation();
  }
  const onPersonClickGoTo = (person: Owner, e: React.MouseEvent) => {
    const goTo = getOnPersonClickGoTo && getOnPersonClickGoTo(person);
    if (goTo) {
      props.history.push(goTo);
    }
    e.stopPropagation();
  }

  const ownersTable = (
    <NamesTable>
      <NamesTableHead>
        <NamesTableRow>
          <NamesTableCellLeft>{leftSideTitle}</NamesTableCellLeft>
          <NamesTableCellRight>{rightSideTitle}</NamesTableCellRight>
        </NamesTableRow>
      </NamesTableHead>
      {people.length > 0 && <NamesTableBody>
        {people.map((person: Owner, i: number) => (
          <NamesTableRow key={i} onClick={e => onPersonClickGoTo(person, e)}>
            <NamesTableCellLeft>
              <NamesTableCellContent>{person.name}</NamesTableCellContent>
            </NamesTableCellLeft>
            <NamesTableCellRight style={{ flex: 1 }}>{getRightSideText(person)}</NamesTableCellRight>
          </NamesTableRow>
        ))}
      </NamesTableBody>}
    </NamesTable>
  );

  if (popup) {
    return (
      <SpaceAssignsPopupContainer onClick={onClickHandler}>
        <Header>{title}</Header>
        <CloseIcon size={24} color={colors.DARK_GRAY} onClick={onCloseHandler} />
        <Subheader>{subtitle}</Subheader>
        {ownersTable}
      </SpaceAssignsPopupContainer>
    );
  }

  return (
    <EmployeeUiLayout onClick={onClickHandler}>
      <ReturnButton onClick={() => props.history.push(onCloseGoTo)} title={backButtonText || ''} />
      <Header>{title}</Header>
      <Subheader>{subtitle}</Subheader>
      {ownersTable}
    </EmployeeUiLayout>
  );
});

export default PeopleModal;
