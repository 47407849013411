import BrandSelect from "./BrandSelect";
import React from "react";
import { MobXProviderContext } from 'mobx-react';
import { Option, Floor } from "../types/floorplan";
import { FloorPlanStore } from "../stores/floorplan";

const floorToOption = (floor: Floor): Option => ({
  value: "" + floor.id,
  label: floor.title,
  disabled: false,
});

interface IProps {
  onChange?: (e: any) => void;
}

const FloorSelect: React.FunctionComponent<IProps> = (props: IProps) => {
  const { floorPlanStore } = React.useContext<{ floorPlanStore: FloorPlanStore }>(MobXProviderContext);
  const { onChange } = props;

  const nonExistingValue: Option = {
    value: '-',
    label: '-',
    disabled: true,
  };
  const defaultValue = floorPlanStore.currentFloor ?
    floorToOption(floorPlanStore.currentFloor) :
    nonExistingValue;
  const options = floorPlanStore.floors.length > 0
    ? floorPlanStore.floors.map(floorToOption)
    : [nonExistingValue];

  const setCurrentFloor = (floorValue: string) => {
    const floor = floorPlanStore.floors.find(f => floorValue === ""+f.id);
    if (floor) {
      floorPlanStore.setCurrentFloor(floor);
    }
  }

  return (
    <div style={{ zIndex: 100 }}>
      <BrandSelect
        options={options}
        onChange={(selected) => {
          setCurrentFloor(selected.value);
          if (onChange) {
            onChange(selected);
          }
        }}
        value={defaultValue}
      />
    </div>
  );
};

export default FloorSelect;
