import styled from "styled-components";
import colors from "../utils/colors";

const scrollbarWidth = "8px";
const transparentColor = "rgba(255, 255, 255, 0)";
const bgColor = colors.BG_BLUE_LIGHT;
const thumbColor = colors.TURQUOISE_LIGHT;

export const ScrollableContainer = styled.div`
  margin-right: ${scrollbarWidth};
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: ${scrollbarWidth};
  }

  &::-webkit-scrollbar-track-piece {
    background: linear-gradient(
      ${transparentColor} 0%,
      ${transparentColor} ${scrollbarWidth},
      ${props => props.theme.supportingColorLight || bgColor} ${scrollbarWidth},
      ${props => props.theme.supportingColorLight || bgColor} calc(100% - ${scrollbarWidth}),
      ${transparentColor} calc(100% - ${scrollbarWidth}),
      ${transparentColor} 100%
    );
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(
      ${transparentColor} 0%,
      ${transparentColor} ${scrollbarWidth},
      ${props => props.theme.primaryColorLight || thumbColor} ${scrollbarWidth},
      ${props => props.theme.primaryColorLight || thumbColor} calc(100% - ${scrollbarWidth}),
      ${transparentColor} calc(100% - ${scrollbarWidth}),
      ${transparentColor} 100%
    );
  }
`;

export const SCROLLBAR_WIDTH_CSS = scrollbarWidth;
