import constants from "./constants";
import { isString } from "lodash";
import React from "react";

export const onMinWidth = (minWidth: any) => isString(minWidth)
  ? `@media only screen and (min-width: ${minWidth})`
  : `@media only screen and (min-width: ${minWidth}px)`;

export const onDesktop = onMinWidth(constants.BREAKPOINT_DESKTOP);

/*
  OnlyOnDesktop and OnlyOnMobile are defined as a React class, instead of just
  some styled div elements, because in some cases (like on the information
  dashboard) we really want to prevent components from rendering (to avoid eg.
  unnecessary fetching of data). If these would be just styled divs with
  hidden elements, the actual React components would still be rendered, thus
  tiggering data fetching.
*/

type IState = {
  render: boolean;
}
type IProps = {};
class BreakpointComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.recalculateNeedForRender = this.recalculateNeedForRender.bind(this);
    this.state = { render: false };
  }

  componentShouldRender(windowWidth: number) {
    return false;
  }

  recalculateNeedForRender() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const render = this.componentShouldRender(width);
    this.setState({ render });
  }

  componentDidMount() {
    window.addEventListener('resize', this.recalculateNeedForRender);
    this.recalculateNeedForRender();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.recalculateNeedForRender);
  }

  render() {
    return this.state.render ? this.props.children : null;
  }
}

export class OnlyOnDesktop extends BreakpointComponent {
  componentShouldRender(windowWidth: number) {
    return windowWidth >= constants.BREAKPOINT_DESKTOP_NAKED;
  }
}

export class OnlyOnMobile extends BreakpointComponent {
  componentShouldRender(windowWidth: number) {
    return windowWidth < constants.BREAKPOINT_DESKTOP_NAKED;
  }
}