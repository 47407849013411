import React from "react";
import styled from "styled-components";
import { TableTag } from "../types/floorplan";
import colors from "../utils/colors";

interface IProps {
  tag: TableTag;
  onClick: (event: React.MouseEvent) => void;
  zoom: number;
  onMobile?: boolean;
}

const TagContainer = styled.div`
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  &:hover {
    cursor: pointer;
  }
`;

const Table = styled.div`
  background-color: ${colors.TURQUOISE};
  height: 36px;
  width: 36px;
  border-radius: 18px;
  display: inline-block;
`;

const Title = styled.p`
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.4px;
  line-height: 36px;
  color: ${colors.WHITE};
  &:hover {
    cursor: pointer;
  }
  padding-top: 2px;
`;

const TableMarker: React.FunctionComponent<IProps> = (props: IProps) => {
  const { tag, onClick, zoom, onMobile } = props;

  return (
    <TagContainer
      style={{
        top: onMobile ? `${tag.positionY * zoom}%` : `${tag.positionY}%`,
        left: onMobile ? `${tag.positionX}%` : `${tag.positionX * zoom}%`,
      }}
      onClick={event => onClick(event)}
    >
      <Table>
        <Title>{tag.space + tag.letter}</Title>
      </Table>
    </TagContainer>
  );
};
export default TableMarker;
