import { SpaceTag, Floor, TableTag, Owner, FloorImage, Move, Company, FloorSize } from "../types/floorplan";
import { FloorMarker, FloorLayout, FloorMarkerOwner, GeneralInfo, WritableMove } from "../types/api";
import { flatten, isNil } from "lodash";
import { backendURL } from "./api";

/*
  PLEASE NOTE: These helpers are moved here from the API module to clear things outs. If we ever
  have time to refactor this properly, these should actually be methods of the related stores,
  and not these kind of external helper functions. Please do not add new functions here, but
  implement those on the stores themselves directly.
*/

export const SpaceTagToMarker = (tag: SpaceTag, floor: Floor) => {
  const marker: FloorMarker = {
    id: tag.id,
    move_layout_id: floor.id,
    marker_type: "room",
    title: tag.title,
    coordinates: {
      positionX: tag.positionX,
      positionY: tag.positionY,
      openSpace: tag.openSpace,
    },
  };
  return marker;
};

export const TableTagToMarker = (tag: TableTag, floor: Floor) => {
  const marker: FloorMarker = {
    id: tag.id,
    move_layout_id: floor.id,
    marker_type: "table",
    title: tag.letter,
    coordinates: {
      positionX: tag.positionX,
      positionY: tag.positionY,
      space: tag.space,
    },
  };
  return marker;
};

export const parseLayout = (layout: FloorLayout): Floor => {
  const spaces: SpaceTag[] = layout.markers
    .filter(marker => marker.marker_type === "room")
    .map(space => {
      return {
        id: space.id,
        title: space.title,
        positionX: space.coordinates.positionX,
        positionY: space.coordinates.positionY,
        openSpace: isNil(space.coordinates.openSpace) ? false : space.coordinates.openSpace,
        tables: [],
      };
    });

  // Parse tables and connect to their spaces
  layout.markers
    .filter(marker => marker.marker_type === "table")
    .forEach(marker => {
      const tableSpace = marker.coordinates.space;
      if (!isNil(tableSpace)) {
        const correctSpace = spaces.find(space => space.title === tableSpace);
        if (!isNil(correctSpace)) {
          const newMarker: TableTag = {
            id: marker.id,
            space: tableSpace,
            letter: marker.title,
            positionX: marker.coordinates.positionX,
            positionY: marker.coordinates.positionY,
          };
          correctSpace.tables.push(newMarker);
        }
      }
    });

  const floorImg: FloorImage = {
    title: layout.filename,
    url: backendURL('') + layout.url,
  };

  return {
    id: layout.id,
    title: layout.floor_name,
    image: floorImg,
    labelColor: layout.labelColor || "none",
    floorSize: layout.floorSize as FloorSize,
    spaces,
  };
};

export const getOwners = (layout: FloorLayout): FloorMarkerOwner[] => {
  const findSpaceId = (spaceTitle: string) => {
    const marker = layout.markers.find(marker => marker.title === spaceTitle);
    if (marker && marker.id) {
      return marker.id;
    }
    return null;
  }

  return flatten(layout.markers.map(marker => {
    if (!marker.users) {
      return [];
    }
    return marker.users!.map(user => {

      // Defaults for marker.marker_type === "room"
      let spaceId: number = marker.id!;
      let tableId: number | null = null;
      if (marker.marker_type === "table") {
        spaceId = findSpaceId(marker.coordinates.space!)!;
        tableId = marker.id!;
      }

      return {
        id: user.id,
        name: user.name,
        stickersNumber: user.stickers_number,
        floorId: layout.id,
        spaceId,
        tableId,
      };
    })
  }));
};

// From FloorMarkerOwner[] to Owner[]
export const parseAssigns = (layout: FloorLayout, floor: Floor) => {
  const ownersAssigned: Owner[] = [];
  if (!isNil(floor)) {
    getOwners(layout).forEach(owner => {
      const space = floor.spaces.find(space => space.id === owner.spaceId);
      if (!space) {
        return;
      }

      const table = space.tables.find(table => table.id === owner.tableId) || null;

      ownersAssigned.push({
        id: owner.id ? owner.id : undefined,
        key: owner.id ? String(owner.id) : Math.random().toString(36).substring(7),
        name: owner.name,
        stickersNumber: owner.stickersNumber,
        floor,
        space: space,
        table: table,
      });
    });
  }
  return ownersAssigned;
};

export const parseMove = (apiResponseData: any): Move => {
  const c: Company = apiResponseData.company;
  const gis: GeneralInfo[] = apiResponseData.key_facts;
  return {
    name: apiResponseData.name,
    company: c,
    generalInfos: gis,
    locale: apiResponseData.locale,
    stickersNumber: apiResponseData.stickers_number,
  };
};

export const moveToWritableMove = (move: Move): WritableMove => {
  return {
    stickers_number: move.stickersNumber,
  };
};
