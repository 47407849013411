import React from "react";
import { SpaceTag } from "../types/floorplan";
import { RouteComponentProps } from "react-router";
import { MobXProviderContext, observer } from "mobx-react";
import { FloorPlanStore } from "../stores/floorplan";
import { useTranslation } from 'react-i18next';
import PeopleModal from "./PeopleModal";

type IProps = RouteComponentProps<any> & {
  onCloseGoTo: string;
  onClickGoTo?: string;
  popup: boolean;
}

const SpaceAssignsModal: React.FunctionComponent<IProps> = observer((props: IProps) => {
  const { floorIdStr, spaceIdStr } = props.match.params;
  const { t } = useTranslation();

  const { floorPlanStore } = React.useContext<{ floorPlanStore: FloorPlanStore }>(MobXProviderContext);
  const floor = floorPlanStore.floors.find(f => f.id === parseInt(floorIdStr, 10));
  let space: SpaceTag | undefined;
  if (floor) {
    space = floor.spaces.find(s => s.id === parseInt(spaceIdStr, 10));
  }

  const people = floorPlanStore.owners.filter(owner => owner.floor && floor && owner.floor.id === floor.id &&
    owner.space && space && owner.space.id === space.id);

  return <PeopleModal
    people={people}
    backButtonText={t('floor_plan.return_button_link')}
    title={space ? t('floor_plan.space_with_id', { id: space.title }) : '-'}
    subtitle={floor ? t('floor_plan.floor_with_id', { id: floor.title }) : '-'}
    leftSideTitle={people.length > 0 ? t('floor_plan.people_assigned') : t('floor_plan.no_people_assigned')}
    rightSideTitle={people.length > 0 ? t('floor_plan.table') : ""}
    getRightSideText={person => person.table ? person.table.letter : '-'}
    {...props}
  />;
});

export default SpaceAssignsModal;
