import React from "react";
import styled from "styled-components";
import { FiTrash2, FiX } from "react-icons/fi";
import colors from "../utils/colors";
import { MarkingType, Option } from "../types/floorplan";
import BrandSelect from "./BrandSelect";
import constants from "../utils/constants";

interface IProps {
  onOK: () => void;
  onDelete: () => void;
  onClose: () => void;
  x: number | null;
  y: number | null;
  options: Option[] | null;
  spaceNumber: string;
  tableLetter: string | null;
  markingType: MarkingType;
  openSpace: boolean;
  onSpaceNumberChange: (name: string) => void;
  onTableLetterChange: (name: string) => void;
  onMarkingTypeChange: (type: MarkingType) => void;
  onOpenSpaceChange: () => void;
  selectedOption: Option | null;
  onOptionSelected: (value: Option) => void;
}

const Container = styled.div`
  width: ${constants.MARKER_TOOL_WIDTH}px;
  z-index: 10;
  border-radius: 2px;
  box-shadow: 0 2px 20px 2px ${colors.TP_GRAY};
  flex-direction: column;
  justify-content: space-evenly;

  position: absolute;
  margin-top: 10px;
  padding: 16px;
  transform: translateX(-50%);
  &:hover {
    cursor: pointer;
  }
  background-color: ${colors.WHITE};
  &:after {
    content: "";
    width: 0px;
    height: 0px;
    transform: translateX(50%);
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid transparent;
    border-bottom: 10px solid ${colors.WHITE};
    right: 50%;
    top: -18px;
  }
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
`;

const OptionTitle = styled.p`
  color: ${colors.GRAY};
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.6px;
  line-height: 16px;
`;

const OKBtn = styled.button`
  height: 30px;
  width: 200px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.6px;
  line-height: 16px;
  border: 0px;
  border-radius: 23px;
  color: ${colors.WHITE};
  background-color: ${colors.GREEN};
`;

const OptionTextField = styled.input`
  height: 28px;
  width: 100px;
  float: right;
  padding: 4px;
  border: 1px solid ${colors.TP_TURQUOISE};
  margin-right: 0px;
  &:focus {
    outline: none;
  }

  color: #3c3c3c;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 14px;
`;

const BrandButton = styled.button`
  flex-grow: 1;
  height: 28px;
  border: 1px solid ${colors.TURQUOISE};
  background-color: ${colors.TURQUOISE};
  color: ${colors.WHITE};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 16px;
  padding-left: 10px;
  &:focus {
    outline: none;
  }
`;

const CloseButton = styled.button`
  position: inherit;
  top: -20px;
  left: 270px;
  width: 26px;
  height: 26px;
  border-radius: 13px;
  border: 0px;
  box-shadow: 0 2px 20px 2px ${colors.TP_GRAY};
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
  &:active {
    background-color: ${colors.WHITE_WARM};
  }
`;

const OptionCheckBox = styled.input.attrs({ type: "checkbox" })`
  margin: 0;
  padding: 0;
`;

const MarkerTool: React.FunctionComponent<IProps> = (props: IProps) => {
  const {
    x,
    y,
    onOK,
    onDelete,
    onClose,
    options,
    spaceNumber,
    tableLetter,
    markingType,
    openSpace,
    onSpaceNumberChange,
    onTableLetterChange,
    onMarkingTypeChange,
    onOpenSpaceChange,
    selectedOption,
    onOptionSelected
  } = props;

  const handleClose = (e: React.MouseEvent) => {
    onClose();
  }

  const handleSelection = (e: any) => {
    onSpaceNumberChange(e.value);
    onOptionSelected({ value: e.value, label: e.value, disabled: false });
  };

  const handleApprove = () => {
    if (markingType === MarkingType.Space) {
      if (spaceNumber === "") {
        // TODO: Focus on spaceNumber field.
        console.log("SpaceNum Empty");
      } else {
        onOK();
      }
    } else if (markingType === MarkingType.Table) {
      if (spaceNumber === "") {
        // TODO: Focus on spaceNumber field.
      } else if (tableLetter === "") {
        // TODO: Focus on tableLetter field.
      } else {
        onOK();
      }
    }
  };

  const renderMarkingType = () => {
    return (
      <>
        <OptionTitle>Marking type</OptionTitle>
        <OptionContainer style={{ marginTop: 8 }}>
          <BrandButton
            onClick={() => onMarkingTypeChange(MarkingType.Space)}
            style={{
              borderBottomLeftRadius: 15,
              borderTopLeftRadius: 15,
              backgroundColor:
                markingType === MarkingType.Space ? colors.TURQUOISE : colors.TP_BLUE,
              color: markingType === MarkingType.Space ? colors.WHITE : colors.TURQUOISE,
            }}
          >
            Space
          </BrandButton>
          <BrandButton
            onClick={() => onMarkingTypeChange(MarkingType.Table)}
            style={{
              borderBottomRightRadius: 15,
              borderTopRightRadius: 15,
              backgroundColor:
                markingType === MarkingType.Table ? colors.TURQUOISE : colors.TP_BLUE,
              color: markingType === MarkingType.Table ? colors.WHITE : colors.TURQUOISE,
            }}
          >
            Table
          </BrandButton>
        </OptionContainer>
      </>
    );
  };

  return (
    <Container
      className="floorplanner-marker-tool"
      style={{
        top: y !== null ? `${y}%` : 0,
        left: x !== null ? `${x}%` : 0,
      }}
      onClick={e => e.stopPropagation()}
    >
      <CloseButton onClick={handleClose}>
        <FiX size={18} color={colors.DARK_GRAY} style={{ position: "inherit", top: 4, left: 4 }} />
      </CloseButton>

      {renderMarkingType()}

      {markingType === MarkingType.Space && (
        <>
          <OptionContainer>
            <OptionTitle>Space number</OptionTitle>
            <OptionTextField
              type="number"
              min="0"
              value={spaceNumber}
              onChange={e => onSpaceNumberChange(e.target.value)}
            />
          </OptionContainer>
          <OptionContainer>
            <OptionTitle>This is an open space</OptionTitle>
            <OptionCheckBox
              type="checkbox"
              name="open"
              checked={openSpace}
              onChange={onOpenSpaceChange}
            />
          </OptionContainer>
        </>
      )}

      {markingType === MarkingType.Table && (
        <>
          <OptionContainer>
            <OptionTitle>Space</OptionTitle>
            <BrandSelect options={options} onChange={handleSelection} value={selectedOption} />
          </OptionContainer>
          <OptionContainer>
            <OptionTitle>Table letter</OptionTitle>
            <OptionTextField
              type="text"
              value={tableLetter || ""}
              onChange={e => onTableLetterChange(e.target.value)}
            />
          </OptionContainer>
        </>
      )}

      <OptionContainer>
        <FiTrash2 size={20} color={colors.RED} onClick={onDelete} />
        <OKBtn onClick={handleApprove}>OK</OKBtn>
      </OptionContainer>
    </Container>
  );
};
export default MarkerTool;
