import React, { Component } from "react";
import { FiAlertCircle, FiTrash2 } from "react-icons/fi";
import * as _ from "lodash";
import { get } from "lodash";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import { FloorPlanStore } from "../stores/floorplan";
import colors from "../utils/colors";
import {
  Table,
  TableCell,
  TableCellContent,
  TableCellContentNoWrap,
  TableHead,
  TableHeadCell,
  TableRow,
} from "./BrandTable";
import BrandSelect from "./BrandSelect";
import { BrandButtonTertiary } from "./BrandButton";
import { Floor, FloorSize, FloorSizeValues, Option } from "../types/floorplan";
import { Subtitle } from "./BrandText";
import { ScrollableContainer, SCROLLBAR_WIDTH_CSS } from "./ScrollableContainer";
import { LabelsStore } from "../stores/labels";

const FloorPlanUploadContainer = styled(ScrollableContainer)`
  height: calc(100% - 48px);
  padding: 24px 32px;
  padding-right: calc(32px + ${SCROLLBAR_WIDTH_CSS});
`;

const FloorPlanTable = styled(Table)`
  max-width: 1000px;
`;

const PlanDescription = styled.p`
  color: ${colors.TEXT_DEFAULT};
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.42px;
  line-height: 18px;
  margin-top: 16px;
`;

const PlaceholderCell = styled(TableCell)`
  color: ${colors.GRAY};
  padding: 0px;
`;

const PlaceholderInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 8px 16px;
  border: 0px;
  color: ${colors.TEXT_DEFAULT};
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 14px;
  &:focus {
    outline: 2px solid ${colors.TP_TURQUOISE};
  }
`;

const FileUploadLabel = styled.label`
  display: block;
  padding-left: 16px;
`;

const FileUploadInfo = styled.span`
  color: ${props => props.theme.primaryColor || colors.TP_TURQUOISE};
  display: inline-block;
  text-transform: uppercase;
`;

const FileUploadName = styled.span`
  color: ${colors.TEXT_DEFAULT};
  padding-right: 16px;
  display: inline-block;
`;

const FileUploadInput = styled.input`
  display: none;
`;

const DeleteBtnContainer = styled.div`
  position: absolute;
  top: 7px;
  left: calc(100% + 16px);

  color: ${props => props.theme.primaryColorLight || colors.RED};
`;

const AddFloorBtn = styled(BrandButtonTertiary)`
  margin-top: 10px;
`;

const FloorPlanThumbs = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
`;

const FloorPlanThumb = styled.div`
  height: 165px;
  width: 206px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 2px solid ${props => props.theme.supportingColorLight || colors.BLUE};
  background-color: ${colors.WHITE};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const ErrorBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #e4cece;
  color: ${colors.RED};
  border-radius: 5px;
  padding: 15px;
  max-width: 300px;
  font-size: 16px;
`;

interface IProps {
  floorPlanStore: FloorPlanStore;
  labelsStore?: LabelsStore;
}

interface IState {
  floorName: string;
  layoutFile: File | null;
  labelColor: Option;
  inputError: string | null;
  floorSize: Option<FloorSize>;
}

function generateOption<T>(value: T): Option<T> {
  return {
    value,
    label: _.capitalize(_.toString(value)),
  };
}

@inject("floorPlanStore")
@inject("labelsStore")
@observer
class FloorPlanUpload extends Component<IProps, IState> {
  private fileInput: any;

  constructor(props: IProps) {
    super(props);
    this.state = {
      floorName: "",
      layoutFile: null,
      labelColor: generateOption(get(props, 'labelsStore.labelColors[0]', 'None')),
      inputError: null,
      floorSize: generateOption(this.getLastFloorSize()),
    };

    this.fileInput = React.createRef();

    this.setFloorName = this.setFloorName.bind(this);
    this.setLayoutFile = this.setLayoutFile.bind(this);
    this.setLabel = this.setLabel.bind(this);
    this.setFloorSize = this.setFloorSize.bind(this);
    this.getLastFloorSize = this.getLastFloorSize.bind(this);
    this.onDeleteClicked = this.onDeleteClicked.bind(this);
  }

  setFloorName(floorName: string) {
    this.setState({ floorName });
  }

  setLayoutFile(layoutFile: File) {
    this.setState({ layoutFile });
  }

  setLabel(labelColor: Option) {
    this.setState({ labelColor });
  }

  setFloorSize(floorSize: Option<FloorSize>) {
    this.setState({ floorSize });
  }

  getLastFloorSize(): FloorSize {
    const { floorPlanStore } = this.props;
    // get the last saved floor
    const lastFloor: Floor | undefined = _.last(floorPlanStore.floors);
    return get(lastFloor, "floorSize", FloorSize.SMALL);
  }

  renderUploadedFloorPlans = () => {
    return this.props.floorPlanStore.floors.map(floor => {
      return <FloorPlanThumb key={floor.id} style={{ backgroundImage: `url(${floor.image.url})` }} />;
    });
  };

  handleChange = (selectorFiles: FileList | null) => {
    if (!_.isNil(selectorFiles) && !_.isNil(selectorFiles[0])) {
      const selectedFile = selectorFiles[0];
      this.setLayoutFile(selectedFile);
    }
  };

  onSaveClicked = async () => {
    const { floorPlanStore } = this.props;
    const { layoutFile, floorName, labelColor, floorSize } = this.state;

    if (this.validateInputs()) {
      // TODO: some loading indicator?
      await floorPlanStore.saveNewLayout(floorName, layoutFile!, labelColor.value, floorSize.value);

      // Reset input state
      this.setState({
        floorName: "",
        layoutFile: null,
        floorSize: generateOption(this.getLastFloorSize()),
        labelColor: generateOption(get(this.props, "labelsStore.labelColors[0]", "None")),
      });

      this.fileInput.value = "";
    }
  };

  onDeleteClicked = async (floor: Floor) => {
    const { floorPlanStore } = this.props;
    await floorPlanStore.deleteLayout(floor.id);
  };

  validateInputs = (): boolean => {
    const { floorPlanStore } = this.props;
    const { layoutFile, floorName } = this.state;

    if (!floorName) {
      this.setState({ inputError: "Please enter a floor number" });
      return false;
    }

    if (!layoutFile) {
      this.setState({ inputError: "Please upload a floor plan" });
      return false;
    }

    if (_.includes(floorPlanStore.floors.map(floor => floor.title), floorName)) {
      this.setState({ inputError: "Floor number or name must be unique" });
      return false;
    }

    this.setState({ inputError: null });
    return true;
  };

  renderNewRow = () => {
    const { floorName, labelColor, layoutFile, floorSize } = this.state;
    const labelColors = get(this.props, 'labelsStore.labelColors', ['None']);

    return (
      <TableRow>
        <PlaceholderCell>
          <PlaceholderInput
            type="number"
            placeholder="Type floor number"
            value={floorName || ""}
            onChange={e => this.setFloorName(e.target.value)}
          />
        </PlaceholderCell>
        <PlaceholderCell align="right">
          <BrandSelect
            value={floorSize}
            onChange={this.setFloorSize}
            options={_.map(FloorSizeValues, generateOption)}
          />
        </PlaceholderCell>
        <PlaceholderCell align="right">
          <BrandSelect
            value={labelColor}
            onChange={this.setLabel}
            options={_.map(labelColors, generateOption)}
          />
        </PlaceholderCell>
        <PlaceholderCell align="right">
          <FileUploadLabel>
            {!layoutFile && <FileUploadInfo>Browse...</FileUploadInfo>}
            <FileUploadName>{layoutFile && layoutFile.name}</FileUploadName>
            {layoutFile && <FileUploadInfo>Change...</FileUploadInfo>}
            <FileUploadInput
              type="file"
              accept=".jpg,.jpeg,.png"
              onChange={e => this.handleChange(e.target.files)}
              ref={ref => (this.fileInput = ref)}
            />
          </FileUploadLabel>
        </PlaceholderCell>
      </TableRow>
    );
  };

  render() {
    const { floorPlanStore } = this.props;
    const { inputError } = this.state;

    return (
      <FloorPlanUploadContainer>
        <Subtitle>Specify floors</Subtitle>
        <PlanDescription>
          Give the floors numbers or names, specify their label colors and upload the floor plans
          in either JPEG or PNG form. You can upload multiple separate maps or layouts (eg.
          different floors or buildings).{" "}
        </PlanDescription>

        <FloorPlanTable>
          <TableHead>
            <tr>
              <TableHeadCell>Floor number</TableHeadCell>
              <TableHeadCell align="right">Floor size</TableHeadCell>
              <TableHeadCell align="right">Label color</TableHeadCell>
              <TableHeadCell align="right">Upload file</TableHeadCell>
            </tr>
          </TableHead>
          <tbody>
            {floorPlanStore.floors.map(floor => {
              return (
                <TableRow key={floor.title}>
                  <TableCell>
                    <TableCellContent>{floor.title}</TableCellContent>
                  </TableCell>
                  <TableCell align="right">
                    <TableCellContent>{_.capitalize(floor.floorSize)}</TableCellContent>
                  </TableCell>
                  <TableCell align="right">
                    <TableCellContent>{_.capitalize(floor.labelColor)}</TableCellContent>
                  </TableCell>
                  <TableCell align="right" style={{ position: "relative" }}>
                    <TableCellContentNoWrap>{floor.image.title}</TableCellContentNoWrap>
                    <DeleteBtnContainer onClick={() => this.onDeleteClicked(floor)}>
                      <FiTrash2 size={16} />
                    </DeleteBtnContainer>
                  </TableCell>
                </TableRow>
              );
            })}
            {this.renderNewRow()}
          </tbody>
        </FloorPlanTable>

        {inputError && (
          <ErrorBox>
            {inputError}
            <FiAlertCircle size={30} color={colors.RED} />
          </ErrorBox>
        )}

        <AddFloorBtn onClick={this.onSaveClicked}>Add floor</AddFloorBtn>

        <Subtitle>Uploaded floor plans</Subtitle>

        <FloorPlanThumbs>{this.renderUploadedFloorPlans()}</FloorPlanThumbs>
      </FloorPlanUploadContainer>
    );
  }
}

export default FloorPlanUpload;
