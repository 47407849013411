import { computed, observable } from "mobx";
import StoresComponent from "./StoresComponent";
import { Label } from "../types/floorplan";
import { sortBy } from "lodash";
import { handleAsyncErrors, apiURL, apiAxiosConfig } from "../utils/api";
import axios from "axios";

export class LabelsStore {
  private storesComponent: StoresComponent | undefined;
  constructor(sc: StoresComponent) {
    this.storesComponent = sc;
  }

  get stores() {
    return this.storesComponent!.stores;
  }

  // --------------------------------------------------------------------------

  @observable
  public labelColors = ["blue", "red", "yellow"];

  @computed
  get labels(): Label[] {
    const { assignedOwners, companyName } = this.stores.floorPlanStore;

    return sortBy(
      assignedOwners.map(owner => ({
        ownerKey: owner.key,
        name: owner.name,
        floor: owner.floor ? owner.floor.title : "-",
        space: owner.space ? owner.space.title : "-",
        table: owner.table ? owner.table.letter : "-",
        color: owner.floor ? owner.floor.labelColor : "-",
        count: owner.stickersNumber,
        roomNumber: `${owner.space ? owner.space.title : ""} ${
          owner.table ? owner.table.letter : ""
        }`.trim(),
        companyName,
      })),
      ["roomNumber", "name"],
    );
  }

  // --------------------------------------------------------------------------

  // Adapted from https://stackoverflow.com/a/53230807
  @handleAsyncErrors({ logErrorMessage: "Error in downloading the CSV file"})
  public async downloadLabelsCsvFile() {
    const response = await axios.get(
      apiURL('/layouts.csv'),
      apiAxiosConfig(this.stores, { responseType: 'blob' })
    );

    const filename = `${this.stores.floorPlanStore.APISubdomain}-label-order.csv`;
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

}
